@import '../../../scss/variables';
@import '../../../scss/mixins';

.skeleton-with-image-table {
    width: 100%;
    min-width: 1040px;

    &__wrapper {
        max-width: 100%;
        overflow-x: auto;

        > div {
            > div {
                &:last-child {
                    @include mq('to', 768) {
                        display: none;
                    }
                }
            }
        }
    }

    &__header {
        width: 100%;
        min-width: 1040px;
        height: 50px;
        background-color: $lightGrey;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        padding: 24px 0 24px 16px;

        @include mq('from', 1921) {
            padding: 24px 16px;
        }
    }

    &__header-element-wrapper {
        min-width: 116px;
        height: 10px;

        @include mq('from', 1921) {
            min-width: 10%;
        }

        span {
            display: block;
            height: 10px;
            @include skeletonLoaderDefaults;

            @include mq('from', 1921) {
                max-width: 100%;
            }

            @include mq('to', 1921) {
                max-width: 90%;
            }
        }

        &:first-child {
            min-width: 180px;

            @include mq('from', 1921) {
                min-width: 22%;

                span {
                    min-width: 210px;
                    max-width: 270px;
                }
            }
        }

        &:last-child {
            min-width: 150px;

            @include mq('from', 1921) {
                min-width: 13%;
            }
        }
    }

    &__content {
        padding: 24px 0 24px 16px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $grey-o24;

        @include mq('from', 1921) {
            padding: 24px 16px;
        }
    }

    span {
        @include skeletonLoaderDefaults;

        &:not(.skeleton-with-image-table__image) {
            @include mq('from', 1921) {
                max-width: 100%;
            }

            @include mq('to', 1921) {
                max-width: 90%;
            }
        }
    }

    &__image-part-name-box {
        display: flex;
        min-width: 180px;

        @include mq('from', 1921) {
            min-width: 22%;

            span {
                &:not(.skeleton-with-image-table__image) {
                    min-width: 210px;
                }
            }
        }
    }

    &__image {
        width: 44px;
        height: 44px;
        background-color: $inactiveGrey;
        margin-right: 16px;
    }

    &__label-value-wrapper {
        min-width: 116px;

        &:last-child {
            min-width: 150px;
            @include mq('from', 1921) {
                min-width: 13%;
            }
        }

        @include mq('from', 1921) {
            min-width: 10%;
        }
    }

    &__labels-comp {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__label,
    &__value {
        min-width: 100px;
        height: 18px;
    }

    &__label-value {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &--two-items {
            height: 100%;
            max-height: 44px;
        }

        &--three-items {
            min-height: 70px;
            max-height: 70px;
        }
    }
}
@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
