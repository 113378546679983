@import '../../../scss/mixins';

.c-page-title {
    @include mq('from', 577) {
        margin-bottom: 32px;
    }

    @include mq('to', 577) {
        margin-bottom: 24px;
    }
}