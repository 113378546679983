@import '../../../scss/variables';
@import '../../../scss/mixins';

.table-skeleton-loader {
    @include emptyState;

    height: 365px;
    width: 100%;
    max-width: 100%;

    @include mq('to', 768) {
        height: 289px;
    }

    &__with-header {
        @extend .table-skeleton-loader;
        height: 415px;
        overflow-y: hidden;
        overflow-x: auto;

        @include mq('to', 768) {
            height: 365px;
        }

        .table-skeleton-loader__lines-wrapper {
            top: 50px;
        }
    }

    &__header {
        max-height: 50px;
        background-color: $lightGrey;
        border-radius: 4px;
        min-width: 100%;

        div {
            width: 100%;
            height: 50px;
            border-bottom: none !important;
            background-color: $lightGrey;
        }
    }

    &__lines-wrapper {
        top: 1px;
    }

    &__lines {
        height: 72px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            span {
                background-color: $inactiveGrey;
            }

            > div {
                border-bottom: 1px solid $grey-o24;
            }
        }

        > div {
            span {
                @include skeletonLoaderDefaults;
                height: 5px;
            }
        }

        &:last-child {
            @include mq('to', 768) {
                display: none;
            }
        }
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
