.c-tooltip {
    position: absolute;
    bottom: calc(100% + 10px);
    background: $white;
    box-shadow: 0px 8px 24px rgba($black, 0.08);
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    z-index: -1;
    transition: $hoverTransition;

    &__content {
        padding: 8px;
        position: relative;
        color: $grey;
        font-size: 14px;
        line-height: 18px;

        &::before {
            content: '';
            width: clamp(11px, 11px, 11px);
            height: clamp(11px, 11px, 11px);
            position: absolute;
            bottom: -6px;
            background: $white;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        }
    }

    &--small {
        min-width: 136px;
    }
}