@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-offer-component {
    &__tabs-btn-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mq('to', 768) {
            flex-direction: column;
        }
    }

    h2 {
        font-size: 16px;
        line-height: 20px;
        color: $black;
        margin-bottom: 16px;
    }

    &__framework-table-title {
        margin-top: 40px;
    }

    &__btns-wrapper {
        @include mq('from', 1550) {
            display: flex;
        }

        @include mq('fromto', 1281, 1550) {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            margin-bottom: 24px;
        }

        @include mq('fromto', 768, 1281) {
            display: flex;
            flex-direction: row;
        }

        @include mq('to', 768) {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-bottom: 24px;
            width: 100%;
        }
        button {
            @include mq('to', 768) {
                padding-left: 10px;
                padding-right: 10px;
            }

            &:last-child {
                margin-left: 8px;

                @include mq('fromto', 1281, 1550) {
                    margin-bottom: 8px;
                }

                @include mq('to', 768) {
                    margin-left: 8px;
                }
            }
        }
    }
}
