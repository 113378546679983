@import '../../scss/variables';
@import '../../scss/mixins';

.c-contact-radio-input {
    border: 2px solid rgba($grey, 0.32);
    transition: $hoverTransition;
    border-radius: 4px;
    cursor: pointer;

    @include mq('from', 601) {
        padding: 24px;
    }

    @include mq('to', 601) {
        padding: 18px;
    }

    &:hover,
    &--checked {
        border-color: $black;
    }

    &--checked {
        .c-contact-radio-input__circle {
            &::before {
                background: $black;
            }

            &::after {
                transform: translate(-50%, -50%) scale(0.6);
            }
        }
    }

    input {
        display: none;
    }

    &__title-container {
        @include flexBetween;
        margin-bottom: 8px;
    }

    &__title {
        color: $black;
        @include creatoBold;
        font-size: 18px;
        line-height: 22px;
    }

    &__description {
        color: $grey;
        font-size: 14px;
        line-height: 18px;
    }

    &__circle {
        border-radius: 50%;
        width: clamp(24px, 24px, 24px);
        height: clamp(24px, 24px, 24px);
        overflow: hidden;
        position: relative;

        &::after,
        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transform-origin: center;
            transition: 0.3s ease;
        }

        &::before {
            background: rgba($grey, 0.32);
            width: 24px;
            height: 24px;
        }

        &::after {
            width: 20px;
            height: 20px;
            background: $white;
        }
    }

    &--disabled {
        opacity: 0.32;
        pointer-events: none;
    }
}