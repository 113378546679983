@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.c-contact {
    border-bottom: 1px solid $grey;
    @include flexBetweenMiddle;
    min-width: 646px;
    gap: 20px;

    @include mq('from', 768) {
        padding: 24px 0;
    }

    @include mq('to', 768) {
        padding: 20px 0;
    }

    &__basic-info {
        font-size: 14px;
        line-height: 18px;
        position: relative;
        max-width: 260px;
        width: 100%;
    }

    &__name {
        @include creatoBold;
        color: $black;
    }

    &__email {
        color: $grey;
        @include overflowEllipsis;
        max-width: 260px;

        &:hover {
            .c-contact__email-tooltip {
                opacity: 1;
            }
        }

        &--with-cursor {
            cursor: pointer;
        }
    }

    &__email-tooltip {
        position: absolute;
        background-color: $white;
        left: 0;
        top: 44px;
        pointer-events: none;
        padding: 8px;
        transition: $hoverTransition;
        border-radius: 2px;
        box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);
        color: $grey;
        opacity: 0;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 28px;
            top: 0em;
            left: 20px;
            box-sizing: border-box;
            border: 5px solid;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);
        }
    }

    &__role-status {
        display: flex;
        min-width: 200px;
        max-width: 200px;
        @include flexBetweenMiddle;
        color: $black;
    }

    &__role {
        font-size: 14px;
        line-height: 18px;
        margin-right: 5px;
        word-break: break-word;
    }

    &__portal-status {
        font-size: 12px;
        line-height: 14px;
        @include creatoBold;
        text-transform: lowercase;
        padding: 3px 15px;
        border-radius: 16px;

        &--active {
            background-color: $green;
        }

        &--not-invited {
            background-color: $grey;
        }

        &--invited {
            background-color: $yellow;
        }
    }

    &__portal-role {
        &--disabled {
            width: clamp(105px, 105px, 105px);
            @include creatoBold;
            color: $grey;
            padding-right: 13px;
            text-align: right;
            font-size: 14px;
            line-height: 18px;
        }
    }
}
