@import '../../../scss/mixins';
@import '../../../scss/variables';

.wizzard-header {
    border-bottom: 1px solid $grey-o24;
    position: relative;
    margin-bottom: 48px;
    width: 100%;
    position: relative;

    @include mq('from', 1281) {
        padding: 32px 64px;
    }

    @include mq('fromto', 769, 1281) {
        padding: 32px;
    }

    @include mq('fromto', 376, 769) {
        padding: 24px 17px 24px 24px;
    }

    @include mq('to', 769) {
        max-height: 72px;
    }

    @include mq('to', 376) {
        padding: 16px 9px 16px 16px;
    }

    &__orange-line {
        display: block;
        height: 4px;
        background-color: $orange;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0px;
        transition: width 0.3s ease-in-out;

        &--1 {
            width: 33.33%;
        }

        &--2 {
            width: 66.66%;
        }

        &--3 {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }

    &__actions {
        @include flexMiddle;
    }

    &__link {
        color: $grey;
        @include creatoBold;
        @include flexMiddle;
        margin-right: 24px;

        span {
            font-size: 14px;
            line-height: 18px;
            margin-left: 9.5px;
        }

        &:hover {
            color: $black;

            i {
                color: $black;
            }
        }

        i {
            font-size: 20px;
            transition: $hoverTransition;
        }
    }

    &__cancel-btn-x {
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 5px;
        transition: all 0.3s ease;
        width: 30px;
        height: 30px;

        @media (hover: hover) {
            &:hover {
                transform: rotate(180deg);

                i {
                    color: $black;
                }
            }
        }

        i {
            font-size: 14px;
            color: $grey;
        }
    }

    &__logo {
        width: 135px;
        height: 32px;

        @include mq('to', 769) {
            width: 101px;
            height: 24px;
        }
    }

    &__item-wrapper {
        display: flex;
        @include flexBetween;
    }

    &__chevron-right-icon {
        @include mq('to', 1281) {
            display: none;
        }
    }

    &__item {
        margin-right: 20px;
        display: flex;
        align-items: center;
        color: $black;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;

        @include mq('to', 1281) {
            margin-right: 0;
        }

        i {
            font-size: 12px;
            margin-left: 20px;
            color: $grey-o32;
        }

        &:last-child {
            margin-right: 0;
            i {
                display: none;
            }
        }

        &--active {
            i {
                color: $black;
            }

            .wizzard-header__step-number {
                background-color: $orange;
                color: $white;
                border-color: $orange;
            }

            .wizzard-header__step-name {
                color: $black;
            }
        }

        &--completed {
            i {
                color: $black;
            }

            .wizzard-header__step-name {
                color: $black;
            }
        }
    }

    &__completed-icon {
        width: 24px;
        height: 24px;

        &:not(:last-child) {
            margin-right: 12px;
        }

        @include mq('to', 768) {
            display: none;
        }
    }

    &__step-number {
        margin-right: 12px;
        font-size: 14px;
        border-radius: 32px;
        color: $grey-o32;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid $grey-o32;
        font-family: $creatoDisplay;
        font-weight: 700;

        @include mq('to', 768) {
            display: none;
        }
    }

    &__step-name {
        color: $grey-o32;
        font-family: $creatoDisplay;
        font-weight: 700;
        line-height: 18px;

        @include mq('to', 1281) {
            display: none;
        }
    }
}
