@import '../../scss/mixins';
@import '../../scss/variables';

.summary {
    &__wrapper {
        display: flex;
        justify-content: space-between;

        @include mq('to', 1551) {
            flex-direction: column;
        }
    }

    &__right-side-grey-section-heading,
    &__intro-heading {
        @include mq('from', 768) {
            margin-bottom: 32px;
        }

        @include mq('to', 768) {
            margin-bottom: 24px;
        }
    }

    h4 {
        margin-bottom: 0;
        font-weight: 700;
        color: $black;
        background-color: $lightGrey;
        border-radius: 4px 4px 0 0;

        @include mq('from', 1281) {
            padding: 34px 24px 8px 16px;
        }

        @include mq('to', 1281) {
            padding: 34px 16px 8px;
        }

        @include mq('from', 768) {
            font-size: 24px;
            line-height: 29px;
        }

        @include mq('to', 768) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__left-side {
        width: 100%;

        @include mq('from', 1551) {
            max-width: calc(100vw - 128px - 576px);
        }
    }

    &__right-side {
        width: 100%;

        @include mq('from', 1551) {
            max-width: 576px;
            margin-left: 31px;
        }

        @include mq('to', 1551) {
            margin-left: 0;
            margin-top: 40px;
            max-width: 100%;
        }
    }

    &__right-side-grey-section {
        background-color: $lightGrey;
        border-radius: 4px;
        margin-bottom: 31px;

        @include mq('from', 768) {
            padding: 32px 24px 40px;
        }

        @include mq('to', 768) {
            padding: 32px 16px 40px;
        }
    }

    &__ordering-instructions-sum {
        display: flex;
        justify-content: space-between;
        margin-top: 19px;
        width: 100%;
        padding-right: 24px;

        @include mq('to', 1281) {
            padding-right: 16px;
        }

        @include mq('to', 768) {
            flex-direction: column-reverse;
        }
    }

    &__ordering-instructions {
        padding-right: 30px;

        @include mq('from', 1921) {
            max-width: 80%;
        }

        @include mq('fromto', 768, 1921) {
            max-width: 64%;
        }

        @include mq('to', 768) {
            max-width: 100%;
            padding-right: 0;
            margin-top: 32px;
        }

        h6 {
            color: $grey;
            font-size: 14px;
            margin-bottom: 6px;
            line-height: 18px;
        }

        ul {
            li {
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                color: $grey;
                padding-left: 20px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 7px;
                    left: 10px;
                    width: 4px;
                    height: 4px;
                    border-radius: 10px;
                    background-color: $grey;
                }
            }
        }
    }

    &__sum {
        min-width: 266px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    &__sum-row {
        margin-bottom: 7px;
        display: flex;

        &:nth-child(2) {
            margin-bottom: 15px;
        }

        &--brutto {
            margin-bottom: 0;
            padding-top: 10px;
            border-top: 1px solid $sum-border-grey;
        }
    }

    &__sum-row-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $secondaryFont;
        color: $grey;
        width: 100px;
        text-align: right;
        min-width: 150px;

        &--brutto {
            font-size: 18px;
            line-height: 22px;
            @include creatoBold;
        }
    }

    &__sum-row-value {
        min-width: 110px;
        text-align: right;
        padding-left: 10px;
        color: $black;
        @include creatoBold;
        line-height: 20px;

        &--brutto {
            font-size: 18px;
        }
    }

    //*Right side order details
    &__order-details-boxes-wrapper {
        @include mq('from', 768) {
            @include flexMultipleItemsInRow(24px, 32px, 2);
        }

        & > * {
            @include mq('to', 768) {
                margin-bottom: 24px;
            }
        }
    }

    &__order-details-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        word-break: break-word;

        &--comment {
            margin-bottom: 0;

            @include mq('from', 768) {
                margin-top: 32px;
            }

            @include mq('to', 768) {
                margin-top: 24px;
            }
        }

        h6 {
            color: $grey;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            font-family: $secondaryFont;

            @include mq('from', 768) {
                margin-bottom: 8px;
            }

            @include mq('to', 768) {
                margin-bottom: 6px;
            }
        }

        span {
            color: $black;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }

        p {
            color: $black;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__one-line {
        display: flex;
        flex-wrap: wrap;
        white-space: break-spaces;
    }

    &__address-content {
        color: $black;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    &__contacts {
        @include mq('from', 768) {
            margin-top: 32px;
        }

        @include mq('to', 768) {
            margin-top: 24px;
        }
    }

    &__contact-name {
        font-size: 14px;
        line-height: 18px;
    }

    &__contact-email {
        &:is(p) {
            color: $grey;
            font-size: 12px;
            line-height: 14px;
        }
    }

    &__multiple-items {
        @include mq('from', 768) {
            @include flexMultipleItemsInRow(24px, 32px, 2);
        }

        > * {
            @include mq('to', 768) {
                margin-bottom: 24px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
