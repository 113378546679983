@import '../../scss/variables';
@import '../../scss/mixins';

.c-svelte-loader {
    @include flexCenterMiddle;
    height: 100%;
    font-size: 14px;

    > .spinner-border {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        flex-shrink: 0;
        border: 3px solid;
        border-color: $grey $black $grey $grey ;
        border-radius: 50%;
        vertical-align: -1.75px;
        animation: 0.75s linear infinite spinner-animation;
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}