.c-date-picker-calendar {
    padding-top: 12px;

    &--English {
        .DayPicker_weekHeader {
            &::before {
                content: 'CW';
            }
        }
    }

    &--Deutsch {
        .DayPicker_weekHeader {
            &::before {
                content: 'KW';
            }
        }
    }

    // fixes for single date calendar
    .CalendarDay__selected {
        background: url('../../images/date-picker/purple-circle-full-white-bg.svg') no-repeat !important;
        color: $white !important;

        @include mq('from', 485) {
            background-size: 52px !important;
        }

        @include mq('to', 485) {
            background-size: 52px !important;
            background-position-x: center !important;
        }
    }

    .CalendarDay__blocked_out_of_range {
        pointer-events: none;
        background: url('../../images/date-picker/line-through.svg') no-repeat !important;

        @include mq('to', 485) {
            background-size: 100% !important;
            background-position-y: 4px !important;
        }
    }
    // end of fixes for single date calendar

    .DayPicker,
    .DayPicker_transitionContainer {
        @include mq('from', 1025) {
            width: 880px !important;
        }

        @include mq('fromto', 550, 1025) {
            width: 415px !important;
        }

        @include mq('fromto', 485, 550) {
            width: 435px !important;
        }

        @include mq('fromto', 390, 485) {
            width: 337px !important;
        }

        @include mq('to', 390) {
            width: calc(100vw - 30px) !important;
            min-width: 319px;
        }
    }

    .DayPicker_transitionContainer {
        padding: 0 0 10px !important;
        box-sizing: content-box;
    }


    .DayPicker > div > div {
        @include mq('from', 1025) {
            width: 880px !important;
        }
    }

    .DayPicker__withBorder {
        box-shadow: none;
    }

    .DayPickerKeyboardShortcuts_buttonReset_1 {
        display: none;
    }

    .CalendarMonth {
        padding: 0 !important;
    }

    .CalendarMonth_table {
        width: 100%;
        margin-top: 16px;
        border-spacing: 0 2px !important;
        border-collapse: unset;

        tr {
            position: relative;

            td {
                border: none;
                color: $grey;
                margin-right: 16px !important;
                min-width: 39px;
                max-width: 39px;

            }

            & > .CalendarDay {
                border-radius: 4px 0 0 4px;

                &:not(.CalendarDay__selected_span) {
                    &:hover {
                        background: url('../../images/date-picker/purple-circle-empty.svg') no-repeat center;
                        background-size: 55px;
                    }
                }

                & ~ .CalendarDay {
                    border-radius: 0;
                }

                &:last-child {
                    border-radius: 0 4px 4px 0;
                }

                span {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 14px;
                    line-height: 18px;
                    pointer-events: none;
                    color: $black;

                    @include mq('from', 485) {
                        left: -36px;
                    }

                    @include mq('to', 485) {
                        left: -25px;
                    }
                }
            }

            & > .CalendarDay ~ .CalendarDay {
                span {
                    display: none;
                }
            }
        }
    }

    .CalendarMonth_caption {
        @include creatoBold;
        font-size: 16px;
        line-height: 20px;
        padding-top: 31px;
        color: $black;

        @include mq('fromto', 485, 1025) {
            padding-right: 46px;
        }

        @include mq('to', 485) {
            padding-right: 33px;
        }
    }

    .CalendarMonthGrid__horizontal {
        left: 0;
    }

    .DayPicker_weekHeaders__horizontal {
        margin-left: 0;
    }

    .DayPickerNavigation_button {
        border: 1px solid $grey;
        height: 44px;
        width: 44px;
        @include flexCenterMiddle;
        border-color: $grey;
        transition: $hoverTransition;
        border-radius: 4px !important;

        &:hover {
            border-color: $black;

            &::after {
                color: $black;
            }
        }

        &:first-child {
            left: 0;
        }

        &:last-child {
            right: 0;
        }

        svg {
            display: none;
        }

        &::after {
            position: absolute;
            content: '\e90e';
            inset: 0;
            @include flexCenterMiddle;
            font-family: 'icomoon';
            font-size: 10px;
            transition: $hoverTransition;
        }
    }

    .DayPickerNavigation_leftButton__horizontalDefault {
        &::after {
            content: '\e90e';
        }
    }

    .DayPickerNavigation_rightButton__horizontalDefault {
        &::after {
            content: '\e911';
        }
    }

    .DayPicker_weekHeader_li {
        width: 39px !important;
        @include creatoBold;
        color: $black;

       &:not(:last-child) {
            @include mq('from', 485) {
                margin-right: 13.7px;
            }

            @include mq('fromto', 390, 485) {
                margin-right: 2.1px;
            }

            @include mq('fromto', 350, 390) {
                margin-right: calc((100vw - 87.5px - 250px) / 7);
            }

            @include mq('to', 350) {
                margin-right: 0;
            }
        }

        small {
            font-size: 14px;
            line-height: 18px;
        }
    }

    .CalendarMonthGrid_month__horizontal {
        padding: 0 !important;

        @include mq('from', 485) {
            width: 369px;
            margin-left: 46px;
        }

        @include mq('fromto', 390, 485) {
            width: 293px;
            margin-left: 32px;
        }

        @include mq('to', 390) {
            margin-left: 32px;
        }

        @include mq('fromto', 360, 390) {
            width: calc(100vw - 62px) !important;
        }

        @include mq('to', 360) {
            width: 287px;
        }

        &:nth-child(3) {
            margin-left: 96px;
        }
    }

    .DayPicker_weekHeader {
        @include mq('from', 485) {
            width: 417px;
            padding: 13px 0 0 52.7px !important;
        }

        @include mq('fromto', 390, 485) {
            padding: 13px 0 0 38px!important;
            width: 325px;
        }

        @include mq('to', 390) {
            padding: 13px 0 0 calc(10vw) !important;
            width: calc(100vw - 30px);
            min-width: 307px;
        }

        &::before {
            position: absolute;
            left: 0;
            bottom: 1px;
            width: 39px;
            text-align: center;
            @include creatoBold;
            font-size: 14px;
            line-height: 18px;
            color: $black;
        }

        &:last-child {
            @include mq('from', 1025) {
                left: 464px !important;
            }
        }
    }

    .CalendarDay__selected_end {
        background: url('../../images/date-picker/purple-circle-full-end.svg') no-repeat !important;
        color: $white !important;
        background-size: 46px !important;

        @include mq('fromto', 390, 485) {
            background-position-x: -6px !important;
        }

        @include mq('fromto', 360, 390) {
            background-position-x: -1vw !important;
        }

        @include mq('to', 360) {
            background-position-x: -6px !important;
        }


    }

    .CalendarDay__selected_start {
        background: url('../../images/date-picker/purple-circle-full-start.svg') no-repeat !important;
        color: $white !important;
        background-size: 46px !important;

        @include mq('from', 485) {
            background-position-x: 8px !important;
        }

        @include mq('fromto', 390, 485) {
            background-position-x: 2.5px !important;
        }

        @include mq('fromto', 360, 390) {
            background-position-x: 1vw !important;
        }

        @include mq('to', 360) {
            background-position-x: 1px !important;
        }
    }

    .CalendarDay__hovered_span {
        background: $lightGrey;

        &:hover {
            background: url('../../images/date-picker/purple-circle-empty-with-left-grey-bg.svg') no-repeat !important;
            background-size: 47.5px !important;

            @include mq('from', 485) {
                background-position: -2px !important;
            }

            @include mq('fromto', 360, 485) {
                background-position-x: -6px !important;
            }

            @include mq('to', 360) {
                background-position-x: -6px !important;
            }
        }
    }

    .CalendarDay__selected_span {
        background: $lightGrey;

        &:hover {
            background: url('../../images/date-picker/purple-circle-grey-bg.svg') no-repeat;
            background-size: 63px;

            @include mq('from', 485) {
                background-position:  center -4px;
            }

            @include mq('to', 485) {
                background-position: center;
            }
        }
    }

    .DayPicker_focusRegion {
        @include mq('fromto', 485, 1025) {
            width: 415px;
        }

        @include mq('fromto', 390, 485) {
            width: 325px;
        }

        @include mq('to', 390) {
            width: calc(100vw - 31px);
            min-width: 312px;
        }
    }
}
