@import '../../scss/variables';
@import '../../scss/mixins';

.c-error-message {
    padding: 26px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &__content {
        font-size: 14px;
        line-height: 18px;
        font-family: $secondaryFont;
        font-weight: 400;
        margin-left: 16px;
        color: $black;
    }

    :global {
        .icon-checkmark-circle,
        .icon-warning {
            font-size: 20px;
        }
    }

    &--orderDeliveryPopup {
        margin-top: 16px;
    }

    &__related-order-text {
        padding-right: 2px;
        margin-right: 16px;
    }

    &__related-order {
        color: $purple;
        transition: $hoverTransition;
        @include creatoBold;
        cursor: pointer;
        display: inline-block;

        &:hover {
            color: $purple-hover;
        }

        i {
            margin-right: 10px;
            font-size: 13.5px;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    &--errorMessage {
        background: $light-red;

        i {
            color: $red;
        }
    }

    &--offerChanged {
        padding: 24px;
        margin-bottom: 24px;
    }

    &--notification {
        margin: 24px 0 0 0;
        border-radius: 8px;

        svg {
            margin-right: 16px;
            min-width: 20px;
        }

        .c-error-message__content {
            margin-left: 0;
        }
    }

    // theme colors
    &--yellow {
        background-color: $light-yellow;

        > i {
            color: $yellow;
        }
    }

    &--green {
        background: $light-green;

        i {
            color: $green;
        }
    }

    &--lightBlue {
        background: $lightBlue;
    }
    //
}
