.c-table {
    width: 100%;
    box-sizing: border-box;

    &:not(.c-table--orderComponents):not(.c-table--orderDeliveryPopup):not(
            .c-table--frameworkComponents
        ) {
        overflow-x: auto;
    }

    &:is(.c-table--orders) {
        margin-bottom: 40px;
    }

    &--wizardOffers {
        //Specific for 7th item in wizard componets table because of structure (same number in table as react select)
        .c-table-item {
            .c-table-item__row-item {
                .c-table-item__content {
                    .c-table-item__container {
                        display: block;
                    }
                }
            }
        }
    }

    &--4 {
        > div {
            &:not(&:nth-child(1)) {
                min-height: 66px;
            }

            > div {
                @include mq('from', 1440) {
                    min-width: 25%;
                    max-width: 25%;
                }

                @include mq('fromto', 1280, 1440) {
                    min-width: 300px;
                    max-width: 300px;
                }

                @include mq('fromto', 1024, 1280) {
                    min-width: 290px;
                    max-width: 290px;
                }

                @include mq('fromto', 768, 1024) {
                    min-width: 250px;
                    max-width: 250px;
                }

                @include mq('to', 768) {
                    min-width: 200px;
                    max-width: 200px;
                }
            }
        }
    }

    &--offersSingleOrder {
        @include mq('from', 1820) {
            padding-bottom: 30px;
        }

        @include mq('fromto', 1100, 1280) {
            padding-bottom: 30px;
        }
    }
}
