@import '../../scss/variables';
@import '../../scss/mixins';

.c-modal-wrapper {
    position: fixed;
    inset: 0;
    z-index: 15;
    background-color: rgba($pureBlack, 0.5);

    &__modal {
        position: absolute;
        background: $white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-y: auto;

        @include mq('from', 601) {
            border-radius: 4px;
            box-shadow: 0px 8px 24px rgba(17, 21, 34, 0.08);
            max-width: 90vw;
            width: 600px;
            max-height: 98vh;
        }

        @include mq('to', 601) {
            height: 100%;
            width: 100%;
        }

        > div {
            @include mq('from', 601) {
                padding: 48px 32px 24px;
            }

            @include mq('fromto', 375, 601) {
                padding: 24px;
            }

            @include mq('to', 375) {
                padding: 15px;
            }

        }
    }

    &__close {
        position: absolute;
        top: 24px;
        right: 24px;
        width: clamp(32px, 32px, 32px);
        height: clamp(32px, 32px, 32px);
        background: none;
        border: none;
        cursor: pointer;
        color: $black;
        transition: all 0.3s ease;

        @media (hover: hover) {
            &:hover {
                transform: rotate(180deg);
            }
        }

        @include mq('fromto', 375, 601) {
            top: 24px;
            right: 24px;
        }

        @include mq('to', 375) {
            top: 16px;
            right: 16px;
        }
    }
}
