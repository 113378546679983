@import '../../scss/variables';
@import '../../scss/mixins';

.c-auth-wrapper {
    width: 100%;
    display: flex;
    flex: 1;
    height: 100%;

    @include mq('from', 1024) {
        overflow-y: auto;
    }

    @include mq('to', 1024) {
        overflow-y: auto;
        min-height: -webkit-fill-available;
        flex-direction: column;
        align-items: center;
    }

    &__content-container {
        display: flex;
        justify-content: center;

        @include mq('from', 1920) {
            width: 50%;
        }

        @include mq('to', 1920) {
            width: 100%;
        }

        @include mq('from', 1600) {
            padding: 120px 216px 100px 168px;
        }

        //*Created 1600 breakpoint to fix small fixed on between 1920 and 1280
        @include mq('fromto', 1281, 1600) {
            padding: 120px 100px 100px;
        }

        @include mq('fromto', 769, 1281) {
            padding: 120px 32px 100px;
        }

        @include mq('to', 1024) {
            flex: 1;
        }

        @include mq('fromto', 376, 769) {
            padding: 40px 24px 40px;
        }

        @include mq('to', 376) {
            padding: 40px 16px 40px;
        }
    }

    &__content {
        width: 100%;
        max-width: 576px;
    }
}