* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    overflow-x: hidden;
}

.c-app {
    height: 100%;
}

html,
body,
button,
input,
select,
textarea {
    font-size: 16px;
    font-family: $secondaryFont, sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 100px white inset;
    -webkit-tap-highlight-color: transparent;
}

label {
    -webkit-tap-highlight-color: transparent;
}

#root {
    height: clamp(100vh, 100vh, 100vh);
}

html {
    height: 100%;

    @include mq('to', 769) {
        &.scrollblock {
            position: fixed;
            overflow-y: scroll;
            width: 100%;
        }
    }
}

html,
body,
#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

body {
    -webkit-tap-highlight-color: transparent;

    &.noScroll {
        overflow: hidden;
    }
}

main {
    padding: 0;
    display: flex;
    min-height: -webkit-fill-available;
    position: relative;

    @include mq('to', 769) {
        flex-direction: column;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include creatoBold;
    color: $black;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    @include mq('from', 768) {
        font-size: 36px;
        line-height: 44px;
    }

    @include mq('to', 768) {
        font-size: 30px;
        line-height: 35.6px;
    }
}

h2 {
    @include mq('from', 768) {
        font-size: 30px;
        line-height: 35.6px;
    }

    @include mq('to', 768) {
        font-size: 24px;
        line-height: 28.5px;
    }
}

h3 {
    @include mq('from', 768) {
        font-size: 24px;
        line-height: 28.5px;
    }

    @include mq('to', 768) {
        font-size: 20px;
        line-height: 24px;
    }
}

h4 {
    @include mq('from', 768) {
        font-size: 20px;
        line-height: 24px;
    }

    @include mq('to', 768) {
        font-size: 18px;
        line-height: 22px;
    }
}

h5 {
    @include mq('from', 768) {
        font-size: 18px;
        line-height: 22px;
    }

    @include mq('to', 768) {
        font-size: 16px;
        line-height: 20px;
    }
}

h6 {
    @include mq('from', 768) {
        font-size: 16px;
        line-height: 20px;
    }

    @include mq('to', 768) {
        font-size: 14px;
        line-height: 18px;
    }
}

p {
    font-size: 16px;
    line-height: 20px;
}

a {
    transition: $hoverTransition;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

li {
    list-style: none;
}

button {
    -webkit-tap-highlight-color: transparent;
}

//*Button Loader

.loader {
    transition: $hoverTransition;
    margin-left: 8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-top: 2px solid $white;
    border-right: 2px solid $white;
    border-left: 2px solid $grey-o32;
    border-bottom: 2px solid $grey-o32;
    animation: rotate 1.4s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

//kekw
.alen-will-hate-me {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    background-color: white !important;
    z-index: 5;
}

.pb-55 {
    padding-bottom: 55px;
}

.pb-35 {
    padding-bottom: 35px;
}

// fixes to remove arrows from number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}