@import '../../scss/variables';
@import '../../scss/mixins';

.mobile-nav {
    &__header {
        background-color: $black;
        width: 90%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        z-index: 14;
        position: fixed;

        @include mq('from', 376) {
            max-height: 72px;
            padding: 24px 27px 24px 24px;
        }

        @include mq('to', 376) {
            max-height: 56px;
            padding: 16px 19px 16px 16px;
        }
    }

    a {
        img {
            @include mq('from', 1281) {
                width: 169px;
            }

            @include mq('to', 1281) {
                width: 101px;
                height: 24px;
            }
        }
    }

    &__icons {
        display: flex;
        align-items: center;

        button {
            color: $white;
            background: none;
            border: none;
            outline: none;
        }
    }

    &__icon-bell {
        margin-right: 31px;

        i {
            font-size: 19px;
        }
    }

    &__icon-hamburger {
        cursor: pointer;

        i {
            font-size: 12px;
        }
    }

    &__overlay {
        position: fixed;
        width: 100%;
        background-color: transparent;
        opacity: 0;
        pointer-events: none;
        inset: 0;
        display: none;
        z-index: 4;

        &--show {
            display: block;
            @extend .mobile-nav__overlay;
            pointer-events: all;
        }
    }

    &__menu {
        background-color: $black;
        width: 100vw;
        position: fixed;
        inset: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all 0.5s ease;
        z-index: 4;
        transform: translateX(100vw);
        overflow-y: auto;

        @include mq('from', 577) {
            padding: 112px 10px 0 32px;
            max-width: 264px;
        }

        @include mq('fromto', 376, 577) {
            padding: 112px 32px 40px;
        }

        @include mq('to', 376) {
            padding: 96px 32px 40px;
        }

        &--show {
            @extend .mobile-nav__menu;
            transition: all 0.5s ease;
            transform: translateX(calc(100vw - 100%));
            z-index: 13;
        }
    }
}
