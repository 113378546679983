@import '../../scss/mixins';
@import '../../scss/variables';

.result-modal {
    &__icon-title {
        display: flex;
        align-items: center;

        @include mq('to', 601) {
            display: block;
        }

        svg {
            @include mq('to', 601) {
                width: 60px;
                margin-left: -5px;
            }
        }

        h1 {
            margin-left: 32px;

            @include mq('to', 601) {
                margin-left: 0;
            }
        }
    }

    &__btn {
        margin-top: 32px;
        margin-left: auto;
    }

    &__btn-icon {
        @include flexMiddle;
        margin-left: 8px;
    }
}
