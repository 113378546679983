.btn {
    //*Btn general styles
    cursor: pointer;
    border: none;
    transition: $hoverTransition;
    border-radius: 4px;
    @include flexCenterMiddle;
    display: flex;
    justify-content: center;
    @include creatoBold;
    width: fit-content;

    &:focus {
        outline: none;
    }

    &:disabled {
        pointer-events: none;
    }

    &--loading {
        pointer-events: none;
    }

    &--full-width {
        width: 100%;
    }

    //Icon inside button
    i {
        margin-left: 11px;
        transition: $hoverTransition;
    }

    // when only icon shown on small screens
    &--small-only-icon {
        @include mq('to', 577) {
            i {
                margin-left: 0;
            }
        }
    }

    //*Btn 117px
    &--117 {
        min-width: 117px;
    }

    //*Bigger btn
    &--big {
        max-height: 56px;

        @include mq('from', 577) {
            font-size: 18px;
            line-height: 22px;
            padding: 17px 32px 17px 32px;
        }

        @include mq('to', 577) {
            font-size: 14px;
            line-height: 18px;
            padding: 13px 24px 13px 24px;
        }

        i {
            font-size: 14px;
        }
    }

    &--sqaure {
        border-radius: 4px;
    }

    //*Bigger button with icon
    &--big-with-icon {
        padding: 17px 35px 17px 32px;
    }

    &--small,
    &--small-2 {
        font-size: 14px;
        line-height: 18px;
        max-height: 44px;
    }

    //*Smaller btn
    &--small {
        padding: 13px 24px 13px 24px;

        i {
            font-size: 12px;
        }
    }

    &--mobile-login {
        @include mq('to', 577) {
            max-height: 44px;
            padding-top: 13px;
            padding-bottom: 13px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    // Smaller btn without icon
    &--small-2 {
        padding: 13px 24px;
    }

    //*Icon btn big
    &--icon-big {
        padding: 20.5px;

        i {
            margin-left: 0;
            font-size: 15px;
        }
    }

    //*Icon btn small
    &--icon-small {
        padding: 16px;

        i {
            margin-left: 0;
            font-size: 12px;
        }
    }

    //*Purple bg
    &--purple {
        color: $white;
        background-color: $purple;

        @media (hover: hover) {
            &:hover {
                background-color: $purple-hover;
            }
        }

        &.btn--loading {
            &:disabled {
                background-color: $purple-hover !important;
            }
        }

        &:disabled {
            background-color: $grey-o32;
        }
    }

    //*Transparent btn
    &--transparent {
        background-color: transparent;

        &:disabled {
            color: $grey-o32;
            border-color: $grey-o32;

            i {
                color: $grey-o32;
            }
        }
    }

    //*Transparent purple
    &--transparent-purple {
        border: 1px solid $purple;
        color: $purple;

        i {
            color: $purple;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $purple-hover;
                color: $white;

                i {
                    color: $white;
                }
            }
        }
    }

    //*Transparent gray
    &--transparent-grey {
        border: 1px solid $grey;
        color: $grey;

        i {
            color: $grey;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $grey;
                color: $white;

                i {
                    color: $white;
                }
            }
        }
    }

    //*Transparent white
    &--transparent-white {
        border: 1px solid $white-o40;
        color: $white;

        i {
            color: $white;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $white;
                color: $black;

                i {
                    color: $black;
                }
            }
        }

        &:disabled {
            color: $white-o40;
            border-color: $white-o40;

            i {
                color: $white-o40;
            }
        }
    }

    &--icon-left {
        i {
            color: $white;
            margin-left: 0;
            margin-right: 12px;
            transform: scaleX(-1);
        }

        span {
            color: $white;
        }
    }

    // datepicker buttons
    &--rounded {
        border-radius: 22px;
        border: 2px solid $paleGrey;
        background: transparent;
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        transition: $hoverTransition;

        @include mq('from', 1024) {
            padding: 11px 22px;
        }

        @include mq('fromto', 420, 1024) {
            padding: 8px 16px;
        }

        @include mq('from', 550) {
            margin-right: 16px;
        }

        @include mq('fromto', 420, 550) {
            margin-right: 10px;
        }

        @include mq('to', 420) {
            margin-right: 8px;
            padding: 6px 10px;
        }

        &:hover,
        &.active {
            border-color: $black;
            color: $black;
        }
    }

    &--link {
        color: $grey;
        font-size: 14px;
        line-height: 18px;

        i {
            color: $grey;
            margin-right: 14px;
            font-size: 9px;
            margin-left: 0;
        }

        @media (hover: hover) {
            &:hover {
                color: $black;

                i {
                    color: $black;
                    margin-right: 8px;
                }
            }
        }
    }

    &.btn--tab {
        padding: 0;
        background: transparent;
        position: relative;
        color: $grey;

        &::after {
            content: '';
            position: absolute;
            bottom: -5px;
            height: 3px;
            width: 100%;
            pointer-events: none;
            transition: $hoverTransition;
        }

        @media(hover: hover) {
            &:hover {
                color: $black;

                &::after {
                    background: $purple;

                }
            }
        }

        &.active {
            color: $black;

            &::after {
                background: $purple;

            }
        }
    }
}
