@import '../../../scss/mixins';
.c-tabs {
    margin-bottom: 32px;

    ul {
        display: flex;
    }

    &__fa-details {
        @include mq('to', 577) {
            margin-bottom: 16px;
        }
    }
}
