@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.manage-contacts {
    &__header {
        @include flexBetween;
        align-items: flex-start;

        button {
            span {
                @include mq('to', 577) {
                    display: none;
                }
            }

            svg {
                @include mq('from', 577) {
                    margin-left: 8px;
                }
            }
        }
    }
}
