@import '../../../scss/variables';
@import '../../../scss/mixins';
.table-empty-state {
    @include emptyState;
    position: relative;

    &--overflow-auto {
        overflow-x: auto;
    }

    &__lines-wrapper {
        width: 100%;
        position: absolute;
        top: -74px;
        height: 216px;
        z-index: -1;
    }

    &__lines {
        display: flex;
        width: 100%;
        align-items: center;
        max-width: 100%;
        min-width: 100% !important;

        &:nth-child(1) {
            max-width: 100%;
            span {
                background-color: $first-box;
            }

            > div {
                border-bottom: 1px solid $first-box-border;
            }
        }

        &:nth-child(2) {
            span {
                background-color: $second-box;
            }

            > div {
                border-bottom: 1px solid $second-box-border;
            }
        }

        &:nth-child(3) {
            span {
                background-color: $third-box;
            }

            > div {
                border-bottom: 1px solid $third-box;
            }
        }

        > div {
            width: 100%;
            height: 72px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            border-bottom: 1px solid $grey-o24;

            &:first-child {
                padding-left: 16px;
                @include creatoBold;
            }

            &:last-child {
                @include mq('from', 1281) {
                    padding-right: 24px;
                }

                @include mq('to', 1281) {
                    padding-right: 16px;
                }
            }

            span {
                width: 100%;
                border-radius: 5px;
                height: 5px;
                background-color: $inactiveGrey;
            }
        }

        //This is for contacts page, but it has no empty state for now, uncomment if needed
        // &--4 {
        //     div {
        //         min-width: 100%;
        //     }
        // }

        &--offers {
            @include offers;
        }

        &--framework {
            @include framework;
        }

        &--orders {
            @include orders;
        }

        &--order-deliveries {
            @include orderDeliveries;
        }

        &--table-invoices {
            @include orderInvoices;
        }

        &--offerFrameworkOrder {
            &:nth-child(8) {
                display: none;
            }
        }

        &--offersEmpty {
            @include offersEmpty;
        }
    }

    &__content {
        box-shadow: 0px 8px 24px rgba(17, 21, 34, 0.08);
        max-width: 640px;
        margin: 74px auto 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        background-color: $white;
        border-radius: 4px;

        @include mq('from', 768) {
            padding: 40px 48px;
        }

        @include mq('to', 768) {
            padding: 40px 24px;
        }

        a {
            margin-top: 16px;
        }
    }

    &__heading {
        margin-bottom: 16px;
    }

    &__description {
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        font-weight: 400;
    }
}
