@import '../../../scss/mixins';

.c-details-page {
    width: 100%;
    overflow-y: auto;
    height: 100%;

    @include mq('from', 1281) {
        display: flex;
        max-width: calc(100vw - 264px);
    }
}
