// Responsive mixin
@mixin mq($type, $rezMin, $rezMax: null) {
    @if ($type== 'from') {
        @media only screen and (min-width: #{$rezMin}px) {
            @content;
        }
    }

    // ========= TO =========
    @else if ($type== 'to') {
        @media only screen and (max-width: #{$rezMin - 1}px) {
            @content;
        }
    }

    // ========= FROM TO =========
    @else if ($type== 'fromto') {
        @media only screen and (min-width: #{$rezMin}px) and (max-width: #{$rezMax - 1}px) {
            @content;
        }
    }
}

// Flex mixins
@mixin flexGrid($gap, $columns, $offset: 0) {
    flex: 0 0
        calc(
            #{calc(100% / $columns)} - #{calc($gap * ($columns - 1) / $columns)}
        );
    max-width: calc(
        #{calc(100% / $columns)} - #{calc($gap * ($columns - 1) / $columns)}
    );
    width: 100%;
    margin-right: $gap;
    margin-bottom: $gap;

    &:nth-child(#{$columns + $offset}n) {
        margin-right: 0;
    }
}

@mixin flexCenterMiddle {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexBetween {
    display: flex;
    justify-content: space-between;
}

@mixin flexColumnBetween {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@mixin flexCenter {
    display: flex;
    justify-content: center;
}

@mixin flexMiddle {
    display: flex;
    align-items: center;
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin flexColumnBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@mixin flexBetweenMiddle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin inlineFlexMiddle {
    display: inline-flex;
    align-items: center;
}

@mixin flexEnd {
    display: flex;
    justify-content: flex-end;
}

@mixin flexBetween {
    display: flex;
    justify-content: space-between;
}

// Font mixin
@mixin creatoBold {
    font-family: $creatoDisplayBold, sans-serif;
    font-weight: 700;
}

@mixin arialNormal {
    font-weight: 400;
    font-family: Arial, sans-serif;
}

//Table icon
@mixin tableLinkIcon {
    padding-left: 33px;
    position: relative;

    span {
        @include creatoBold;
    }

    &::after {
        content: '';
        background-repeat: no-repeat;
        position: absolute;
        left: 8px;
    }
}

@mixin skeletonLoaderDefaults {
    display: block;
    background: linear-gradient(
        110deg,
        $inactiveGrey 8%,
        $lightGrey 18%,
        $inactiveGrey 33%
    );
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.8s shine linear infinite;
    border-radius: 4px;
}

@mixin overflowEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    overflow: hidden;
}

// mixin for multiple children inside flex parent container with same width in one row and some spacing between
@mixin flexMultipleItemsInRow(
    $spacingBetween,
    $spacingBottom,
    $childrenNumber
) {
    display: flex;
    flex-wrap: wrap;

    > * {
        @include flexGrid($spacingBetween, $childrenNumber);
        margin-bottom: $spacingBottom;
    }
}

// mixin for button reset
@mixin buttonReset {
    border: none;
    cursor: pointer;
    background: none;
}

// TABLE MIXINS
//*ORDERS TABLE
@mixin orders {
    @include mq('from', 1921) {
        min-width: 11.1%;
    }

    @include mq('fromto', 1824, 1921) {
        min-width: 173px;
    }

    @include mq('to', 1824) {
        min-width: 130px;
    }

    &:first-child {
        @include mq('from', 1921) {
            min-width: 11.1%;
        }

        @include mq('fromto', 1824, 1921) {
            min-width: 178px;
        }

        @include mq('to', 1824) {
            min-width: 130px;
        }
    }

    &:last-child {
        @include mq('from', 1921) {
            min-width: 11.1%;
        }

        @include mq('to', 1921) {
            min-width: 210px;
        }
    }

    &:nth-child(4) {
        min-width: 176px;
    }

    &:nth-child(7) {
        min-width: 148px;
    }

    &:nth-child(5) {
        @include mq('from', 1824) {
            min-width: 140px;
            max-width: 172px;
        }

        @include mq('to', 1824) {
            min-width: 140px;
            max-width: 140px;
        }
    }

    &:nth-child(6) {
        min-width: 128px;
    }

    &:nth-child(8) {
        @include mq('to', 1824) {
            max-width: 130px;
        }
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        text-align: right;
    }
}

//*OFFERS TABLE
@mixin offers {
    @include mq('to', 1812) {
        min-width: 130px;
    }

    &:first-child {
        @include mq('from', 1812) {
            min-width: 264px;
        }

        @include mq('to', 1812) {
            min-width: 130px;
        }
    }

    &:last-child {
        @include mq('from', 1812) {
            min-width: 228px;
        }

        @include mq('to', 1812) {
            min-width: 218px;
        }
    }

    &:nth-child(4) {
        min-width: 176px;
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        text-align: right;
    }
}

//*Framework TABLE
@mixin framework {
    @include mq('from', 1024) {
        min-width: 130px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(8) {
        @include mq('to', 1024) {
            min-width: 130px;
        }
    }

    &:nth-child(3),
    &:nth-child(4) {
        min-width: 140px;
    }

    @include mq('to', 1024) {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            min-width: 110px;
        }
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
        text-align: right;
    }
}

@mixin emptyState {
    position: relative;

    &__lines-wrapper {
        width: 100%;
        position: absolute;
        top: -64px;
        height: 216px;
        z-index: -1;
    }

    &__lines {
        display: flex;
        width: 100%;
        align-items: center;

        &:nth-child(1) {
            span {
                background-color: $first-box;
            }

            > div {
                border-bottom: 1px solid $first-box-border;
            }
        }

        &:nth-child(2) {
            span {
                background-color: $second-box;
            }

            > div {
                border-bottom: 1px solid $second-box-border;
            }
        }

        &:nth-child(3) {
            span {
                background-color: $third-box;
            }

            > div {
                border-bottom: 1px solid $third-box;
            }
        }

        > div {
            width: 100%;
            height: 72px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            border-bottom: 1px solid $grey-o24;

            &:first-child {
                padding-left: 16px;
                @include creatoBold;
            }

            &:last-child {
                @include mq('from', 1281) {
                    padding-right: 24px;
                }

                @include mq('to', 1281) {
                    padding-right: 16px;
                }
            }

            span {
                width: 100%;
                border-radius: 5px;
                height: 5px;
                background-color: $inactiveGrey;
            }
        }

        //This is for contacts page, but it has no empty state for now, uncomment if needed
        // &--contacts {
        //     div {
        //         min-width: 100%;
        //     }
        // }

        &--offers {
            @include offers;
        }

        &--framework {
            @include framework;
        }

        &--orders {
            @include orders;
        }

        &--offersSingleOrder {
            @include offersSingleOrder;
            min-width: 130px;

            @include mq('from', 1921) {
                &:first-child {
                    min-width: 350px;
                }
            }
        }

        &--offerFrameworkOrder {
            @include offerFaOrderMinWidth;
        }

        &--frameworkAgreementsOrders {
            @include frameworkAgreementsOrders;

            &:first-child {
                padding-left: 0;
            }
        }

        &--orderComponents {
            @include orderComponents;
            max-width: 100%;
            min-width: 150px;
        }

        &--frameworkComponents {
            min-width: 150px;
        }

        &--frameworkComponentsDetails {
            @include frameworkComponentsDetails;
        }
    }
}

@mixin offersEmpty {
    &:nth-child(1) {
        min-width: 250px;
    }
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        min-width: 160px;
    }

    &:nth-child(5) {
        min-width: 148px;
    }
}

@mixin offersSingleOrder {
    &:first-child {
        min-width: 200px;
        flex: 1;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 608px - 64px) * 0.295454545455);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.295454545455);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.295454545455);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.295454545455);
        }
    }
}

@mixin offersFrameworkOrder {
    &:first-child {
        min-width: 190px;
        width: calc(((156px + 24px + 8px)) / 1056px * 100%);
    }

    &:nth-child(2) {
        min-width: 116px;
        width: calc((100px + 8px + 8px) / 1056px * 100%);
    }
}

@mixin orderDeliveries {
    @include mq('from', 1281) {
        min-width: 156px;
    }

    @include mq('to', 1281) {
        min-width: 130px;
    }

    &:nth-child(3) {
        @include mq('from', 1921) {
            min-width: 238px;
        }

        @include mq('fromto', 1281, 1921) {
            max-width: 156px;
        }

        @include mq('fromto', 1025, 1281) {
            min-width: 146px;
        }

        @include mq('to', 1025) {
            min-width: 136px;
        }
    }

    &:nth-child(4),
    &:nth-child(5) {
        @include mq('to', 1025) {
            min-width: 100px;
            max-width: 100px;
        }
    }

    &:nth-child(5) {
        text-align: right;
    }

    &:nth-child(6) {
        @include mq('to', 1025) {
            min-width: 116px;
            max-width: 116px;
        }
    }
}

@mixin orderInvoices {
    &:nth-child(1),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        min-width: 120px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        min-width: 146px;
    }

    &:nth-child(3) {
        @include mq('from', 1921) {
            min-width: 238px;
        }

        @include mq('fromto', 1281, 1921) {
            max-width: 156px;
        }

        @include mq('fromto', 1025, 1281) {
            min-width: 146px;
        }

        @include mq('to', 1025) {
            min-width: 136px;
        }
    }

    &:nth-child(6) {
        .c-table-item__container {
            justify-content: flex-end;
        }
    }

    &:nth-child(6),
    &:nth-child(7) {
        text-align: right;
    }

    &:nth-child(6) {
        @include mq('to', 1025) {
            min-width: 100px;
            max-width: 100px;
        }
    }
}

@mixin orderComponents {
    &:first-child,
    &:last-child {
        width: calc((100% - 32px) * 5 + 16px);
    }

    &.c-table-item__5,
    &:not(.c-table-header__frameworkComponents).c-table-header--no-top-radius {
        &:first-child {
            min-width: 140px;

            @include mq('from', 1280) {
                padding-left: 24px !important;
            }
        }
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        width: calc((100% - 32px) * 5);
    }

    &:nth-child(2) {
        min-width: 140px;
    }

    &:nth-child(3) {
        min-width: 130px;
    }

    &:nth-child(4) {
        min-width: 100px;
        text-align: right;
    }

    &:last-child {
        min-width: 130px;
    }
}

@mixin orderDeliveryPopup {
    &.c-table-item__5,
    &.c-table-header--no-top-radius {
        &:first-child {
            min-width: 75px;
            width: calc((80px / 896px) * 100%);
            padding-left: 24px;
        }
    }

    &:nth-child(2) {
        min-width: 210px;
        width: calc((256px / 896px) * 100%);
    }

    &:nth-child(3) {
        min-width: 155px;
        width: calc((173px / 896px) * 100%);
    }

    &:nth-child(4) {
        min-width: 180px;
        width: calc((235px / 896px) * 100%);
        text-align: right;
    }

    &.c-table-item__5,
    &.c-table-header--no-top-radius {
        &:nth-child(5) {
            min-width: 140px;
            width: calc((152px / 896px) * 100%);
            padding-right: 24px;
        }
    }
}

@mixin frameworkAgreementsOrders {
    &.c-table-item__8 {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &:first-child {
        min-width: 48px;
        max-width: 48px;
        padding-left: 18px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(7) {
        min-width: 135px;
    }

    &:nth-child(5) {
        min-width: 125px;
    }

    &:last-child {
        min-width: 210px;
    }
}

@mixin frameworkComponents {
    &,
    &.c-table-header--no-top-radius {
        &:first-child {
            min-width: 48px;
            max-width: 48px;
            padding-left: 18px;
        }
    }

    &.c-table-item__8,
    &.c-table-header--no-top-radius {
        &:last-child {
            padding-right: 24px;
        }
    }

    &.c-table-item__8 {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &:nth-child(3) {
        width: calc((145 / 896) * 100%);
    }

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
        width: calc((142.5 / 896) * 100%);
    }

    &:nth-child(7) {
        text-align: right;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        min-width: 140px;
    }

    &:nth-child(8) {
        min-width: 155px;
    }
}

@mixin frameworkComponentsDetails {
    &.c-table-item__8 {
        padding-top: 24px;
        padding-bottom: 24px;

        .c-table-item__content {
            white-space: unset;
        }
    }

    &:first-child {
        min-width: 180px;
        width: calc((204px / 1064px) * 100%);
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        width: calc((116px / 1064px) * 100%);
        min-width: 116px;
    }

    &:nth-child(8) {
        min-width: 150px;
        width: calc((164px / 1064px) * 100%);
    }
}

@mixin offerSingleOrderHeader {
    @include offersSingleOrder;

    &:nth-child(2) {
        min-width: 168px;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 64px) * 0.18045112782 - 100px);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.18045112782 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.18045112782 - 100px);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.18045112782 - 100px);
        }
    }

    &:nth-child(3),
    &:nth-child(4) {
        min-width: 152px;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 64px) * 0.142857142857 - 100px);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.142857142857 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.142857142857 - 100px);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.142857142857 - 100px);
        }
    }

    &:nth-child(5) {
        min-width: 132px;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 64px) * 0.124060150376 - 100px);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.124060150376 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.124060150376 - 100px);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.124060150376 - 100px);
        }
    }

    &:nth-child(6) {
        min-width: 100px;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 64px) * 0.124060150376 - 100px);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.124060150376 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.124060150376 - 100px);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.124060150376 - 100px);
        }
    }

    &:last-child {
        min-width: 210px;

        @include mq('from', 1280) {
            width: calc((100vw - 531px - 64px) * 0.169172932331 - 100px);
        }

        @include mq('fromto', 769, 1280) {
            width: calc((100vw - 64px - 64px) * 0.169172932331 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.169172932331 - 100px);
        }

        @include mq('fromto', 376, 769) {
            width: calc((100vw - 64px) * 0.169172932331 - 100px);
        }

        @include mq('to', 376) {
            width: calc((100vw - 32px) * 0.169172932331 - 100px);
        }
    }
}
@mixin wizardOffers {
    &:first-child {
        min-width: 58px;
        max-width: 58px;
    }

    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
        text-align: right;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6) {
        min-width: 120px;
    }

    &:nth-child(5) {
        min-width: 165px;
    }

    &:nth-child(7),
    &:nth-child(8) {
        min-width: 160px;
    }

    &:nth-child(9),
    &:nth-child(10) {
        min-width: 120px;
    }

    &:nth-child(11) {
        min-width: 200px;
    }

    &:last-child {
        max-width: 56px;
        min-width: 56px;
    }

    &.c-table-item--no-discount {
        &:nth-child(10) {
            display: none !important;
        }
    }

    &.c-table-item--no-origin {
        &:nth-child(7) {
            display: none !important;
        }
    }
}

@mixin wizardSummary {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        min-width: 120px;
    }

    &:nth-child(6),
    &:nth-child(7) {
        text-align: right;
    }

    &:nth-child(4) {
        min-width: 150px;
    }

    &:nth-child(8) {
        min-width: 200px;
    }
}

@mixin faWizardSummary {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
        min-width: 170px;
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
        @include mq('from', 1921) {
            max-width: auto;
        }

        @include mq('fromto', 1551, 1921) {
            max-width: 170px;
        }

        @include mq('to', 1551) {
            min-width: 120px;
        }
    }

    &:nth-child(6),
    &:nth-child(7) {
        text-align: right;
    }
}

@mixin wizardFaOffers {
    &.c-table-item__12,
    &.c-table-item__13,
    &.c-table-item__14 {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &:first-child {
        min-width: 58px;
        max-width: 58px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6) {
        min-width: 120px;
    }

    &:nth-child(5) {
        min-width: 165px;
    }

    &:nth-child(7) {
        min-width: 160px;
    }

    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11),
    &:nth-child(12) {
        min-width: 120px;
    }

    &:nth-child(13) {
        min-width: 192px;
    }

    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
        .c-table-item__container {
            span {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &:nth-child(8) {
        .c-table-item__container {
            align-items: flex-start;

            span {
                @include creatoBold;
                color: $grey;
            }
        }
    }

    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
        .c-table-item__container {
            align-items: flex-end;
        }
    }

    &:last-child {
        max-width: 56px;
        min-width: 56px;
    }

    &:nth-child(9),
    &:nth-child(11),
    &:nth-child(12),
    &:nth-child(13) {
        text-align: right;
    }
}

@mixin offersFaWizardSummary {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        min-width: 128px;
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
        .c-table-item__container {
            span {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &:nth-child(5) {
        .c-table-item__container {
            align-items: flex-start;

            span {
                color: $grey;
                line-height: 18px;
            }
        }
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
        .c-table-item__container {
            align-items: flex-end;
        }
    }

    &:nth-child(6),
    // &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        text-align: right;
    }

    &.c-table-header--no-origin,
    &.c-table-item--no-origin {
        &:nth-child(7) {
            display: none;
        }
    }
}

@mixin offersSingleOrderWizardSummary {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(9) {
        min-width: 139px;
    }

    &:nth-child(4) {
        min-width: 160px;
    }

    &.c-table-header--no-discount,
    &.c-table-item--no-discount {
        &:nth-child(8) {
            display: none;
        }
    }

    &.c-table-header--no-origin,
    &.c-table-item--no-origin {
        &:nth-child(6) {
            display: none;
        }
    }

    &:nth-child(8) {
        min-width: 100px;
    }

    &:nth-child(7),
    &:nth-child(8) {
        text-align: right;
    }
}

@mixin faWizardStepOne {
    &:nth-child(1) {
        min-width: 250px;
    }

    &:nth-child(4) {
        min-width: 160px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
        min-width: 139px;
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10),
    &:nth-child(11) {
        text-align: right;
    }

    &:last-child {
        max-width: 56px;
        min-width: 56px;
    }
}

// offer framework order for table header and certificate min width
@mixin offerFaOrderMinWidth {
    &:first-child {
        min-width: 190px;
    }

    &:nth-child(2) {
        min-width: 116px;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        min-width: 109px;
    }

    &:nth-child(6) {
        min-width: 110px;
    }

    &:last-child {
        min-width: 192px;
    }
}

// when offer framework order for table header and certificate without discount and origin
@mixin offerFaOrderWithoutDiscountAndOrder {
    @include offerFaOrder;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(8) {
        width: calc((100vw - 528px - 264px) * 0.115);
    }

    &:nth-child(10) {
        width: calc((100vw - 528px - 264px) * 0.13);
    }

    &:nth-child(7),
    &:nth-child(9) {
        display: none;
    }
}

// when offer framework order for table header and certificate with discount
@mixin offerFaOrderWithDiscount {
    &:nth-child(1) {
        width: calc((100vw - 528px - 264px) * 0.16);
        flex: 1;
    }

    &:nth-child(2) {
        flex: 1;
    }

    &:nth-child(2),
    &:last-child {
        width: calc((100vw - 528px - 264px) * 0.12);
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
        width: calc((100vw - 528px - 264px) * 0.1);
    }
}

// when offer framework order has origin and discount
@mixin offerFaOrderWithDiscountAndOrigin {
    &:first-child {
        width: calc((100vw - 528px - 264px) * 0.13);
        flex: 1;
    }

    &:nth-child(2) {
        width: calc((100vw - 528px - 264px) * 0.105);
        flex: 1;
    }
}

@mixin offerFaOrderWithOriginOrDiscountHeaderAndCertificate {
    @include offerFaOrderWithOriginOrDiscount;

    &:nth-child(10) {
        width: calc((100vw - 528px - 264px) * 0.12);
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
        width: calc((100vw - 528px - 264px) * 0.1);
    }

    &.c-table-item--no-discount,
    &.c-table-header--no-discount {
        &:nth-child(9) {
            display: none;
        }
    }
}

// when offer framework order has only origin
@mixin offerFaOrderWithOriginOrDiscount {
    &:first-child {
        width: calc((100vw - 528px - 264px) * 0.16);
        flex: 1;
    }

    &:nth-child(2) {
        width: calc((100vw - 528px - 264px) * 0.12);
        flex: 1;
    }
}

// when offer framework order does not have origin or discount
@mixin offerFaOrder {
    &:first-child {
        width: calc((100vw - 528px - 264px) * 0.165);
        flex: 1;
    }

    &:nth-child(2) {
        width: calc((100vw - 528px - 264px) * 0.13);
        flex: 1;
    }
}