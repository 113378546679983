@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.c-tab {
    &:not(:last-child) {
        margin-right: 24px;
    }

    a {
        @include creatoBold;
        font-size: 18px;
        color: $grey;
        position: relative;
        padding-bottom: 14px;
        display: flex;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: transparent;
            transition: $hoverTransition;
        }

        &:global.active {
            color: $black;

            &::after {
                background: $black;
            }
        }

        &:not(:active):hover {
            color: $black;

            &::after {
                background: $black;
            }
        }
    }

    &__btns-wrapper {
        display: flex;
    }

    &__btn {
        padding: 11px 24px 13px;
        border: 2px solid $grey-o32;
        border-radius: 4px;
        max-height: 44px;
        position: relative;
        background-color: $white;
        outline: none;
        font-size: 14px;
        @include creatoBold;
        line-height: 18px;
        color: $grey;
        cursor: pointer;
        z-index: 1;
        margin-left: -5px;
        transition: color 0.4s;

        @include mq('fromto', 769, 1339) {
            margin-bottom: 32px;
        }

        @include mq('fromto', 576, 769) {
            margin-bottom: 24px;
        }

        @include mq('to', 576) {
            margin-bottom: 16px;
        }

        &:hover {
            color: $black;
        }

        &:global.active {
            border-color: $black;
            color: $black;
            border-radius: 4px;
            z-index: 2;
        }

        &:not(.c-tab--details) {
            &:nth-child(1) {
                z-index: 2;

                @include mq('from', 1024) {
                    margin-left: 16px;
                }

                @include mq('to', 1024) {
                    margin-left: 0;
                }
            }
        }
    }

    &--one {
        cursor: pointer;

        a {
            pointer-events: none;
        }
    }

    &--details {
        &.c-tab__btn {
            &:nth-child(1) {
                z-index: 2;
                margin-left: 0;
            }
        }
    }
}
