@import '../../scss/variables';
@import '../../scss/mixins';

.c-iframe-wizard {
    position: fixed;
    inset: 0;

    &__content {
        height: 100%;
        width: 100%;
        opacity: 0;

        &--show {
            opacity: 1;
        }
    }

    &__loading {
        background: $black;
        position: fixed;
        inset: 0;
        @include flexCenterMiddle;
    }
}
