// Black
$black: #111522;
$pureBlack: #000000;

// White
$white: #ffff;
$white-2: #fafafa;
$white-o40: rgba(255, 255, 255, 0.4);

//*Orange
$orange: #fd7e14;

//*Purple
$purple: #5200ff;
$purple-hover: #4504d3;
$light-purple: #C1AAF2;

// Light Blue
$lightBlue: #D9F1FD;

//*Grey
$grey: #838a9c;
$lightGrey: #f3f5f6;
$lightGrey2: #d8dbe0;
$lightGrey3: #d8dbe0;
$dark-grey: #8b898f;
$inactiveGrey: #e6e6ed;
$grey-o32: rgba(131, 138, 156, 0.32);
$grey-o24: rgba(131, 138, 156, 0.24);
$paleGrey: #d7d9df;
$sum-border-grey: #e1e3e7;

//*Yellow
$yellow: #ebc962;
$light-yellow: #f9eecd;

//*Green
$green: #87cc9b;
$light-green: #d9efdf;

//*Red
$red: #da615c;
$light-red: #f6d9d8;
$errorRed: rgba(218, 97, 92, 0.1);

//*Empty message lines
$first-box: #ebeaf0;
$first-box-border: #efeff1;
$second-box: #f3f2f7;
$second-box-border: #f9f9f9;
$third-box: #fcfcfe;

// default font
$creatoDisplay: 'Creato Display';
$creatoDisplayBold: 'Creato Display Bold';
$secondaryFont: 'Arial';

//Hover transition
$hoverTransition: all 0.4s;
