@import '../variables';
.c-react-select {
    width: 100%;
    // z-index: 2;

    &--max-492 {
        @include mq('from', 577) {
            max-width: 492px;
        }

        @include mq('to', 577) {
            max-width: 100%;
        }
    }

    &--212 {
        @include mq('from', 577) {
            min-width: 212px;
            max-width: 212px;
        }

        @include mq('to', 577) {
            min-width: 100%;
        }
    }

    &--105 {
        min-width: 105px;
        max-width: 105px;

        .c-react-select__placeholder {
            @include creatoBold;
            font-size: 14px;
            line-height: 18px;
        }

        .c-react-select__option {
            color: $grey !important;
            @include creatoBold;
        }
    }

    &--mt-8 {
        margin-top: 8px;

        .c-react-select__control {
            &::after {
                top: 12px;
            }
        }
    }

    &--transaprent-grey-border {
        .c-react-select__control {
            border: 2px solid $grey-o32 !important;
            background-color: transparent !important;
            transition: $hoverTransition !important;

            @media (hover: hover) {
                &:hover {
                    border-color: $grey !important;
                }
            }
        }

        .c-react-select__control--menu-is-open {
            border-radius: 4px 4px 0 0 !important;
            border-color: $black !important;

            @media (hover: hover) {
                &:hover {
                    border-color: $black !important;
                }
            }
        }
    }

    &--status-select {
        .c-react-select__single-value {
            font-weight: normal;
            font-family: $secondaryFont;
        }

        .c-react-select__control {
            &::after {
                transition: $hoverTransition;
                color: $grey;
            }
        }

        .c-react-select__control--menu-is-open {
            &::after {
                color: $black;
            }
        }
    }

    &__control {
        height: 100%;
        min-height: 44px !important;
        max-height: 44px;
        background-color: $lightGrey !important;
        caret-color: transparent;
        border: 2px solid transparent !important;
        cursor: pointer !important;
        transition: none !important;

        &::after {
            content: '\e90a';
            font-family: 'icomoon';
            position: absolute;
            right: 16px;
            top: 18px;
            font-size: 4.5px;
        }

        &--menu-is-open {
            background-color: transparent !important;
            border-radius: 4px 4px 0 0 !important;
            border-color: $black !important;
        }

        &--is-focused {
            box-shadow: none !important;
        }
    }

    &__single-value {
        color: $black !important;
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;
    }

    &__value-container {
        padding-left: 10px !important;
        padding-right: 40px !important;
    }

    &__indicator {
        display: none !important;
    }

    &__indicator-separator {
        display: none;
    }

    &__menu {
        border: 2px solid $black !important;
        border-radius: 0 0 4px 4px !important;
        margin-top: 0 !important;
        border-top: none !important;
        z-index: 10 !important;
    }

    &__menu-list {
        background-color: $white !important;
        padding: 0 !important;
    }

    &__option {
        color: $black !important;
        font-family: $secondaryFont;
        font-size: 14px !important;
        line-height: 18px !important;
        font-weight: 400 !important;
        height: 44px;
        display: flex !important;
        align-items: center !important;
        padding-left: 12px !important;
        background-color: transparent !important;
        cursor: pointer !important;
        transition: $hoverTransition !important;

        @media (hover: hover) {
            &:hover {
                background-color: $lightGrey !important;
            }
        }
    }

    &__country {
        font-size: 14px;
        color: $lightGrey;

        .c-react-select__single-value {
            color: $grey !important;
            font-weight: 400;
            font-family: $secondaryFont;
        }

        .c-react-select__control {
            &::after {
                color: $grey;
            }
        }
    }

    &__limited-width {
        .c-react-select__control {
            border: 1px solid transparent !important;
            color: $grey !important;

            @include mq('to', 1025) {
                transition: none !important;
            }
        }

        .c-react-select__menu {
            border: 1px solid $black !important;
            border-top: none !important;
            border-radius: 0 0 2px 2px !important;
        }

        .c-react-select__control--menu-is-open {
            border: 1px solid black !important;
        }

        .c-react-select__option {
            display: block !important;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex !important;
            align-items: center !important;
        }

        .c-react-select__single-value {
            padding-right: 20px;
            color: $grey !important;
            overflow: visible;
        }
    }

    &__multiple-items {
        .c-react-select__value-container {
            padding-right: 30px !important;
        }
    }

    &__multiple-selected-items {
        @include flexMiddle;
        @include arialNormal;
    }

    &__flags {
        margin-right: 4px;
        transform: scale(0.8);
    }

    &__menu-portal {
        z-index: 16 !important;

        &::before {
            content: '';
            position: fixed;
            inset: 0;
        }

        .css-1w9j89e-menu {
            bottom: calc(100% - 2px);
            border-top: 2px solid $black !important;
            border-radius: 4px 4px 0 0 !important;
            margin: 0;
        }
    }

    &__contact-select {
        // color: $black;

        .c-react-select__single-value {
            @include arialNormal;
        }

        .c-react-select__name {
            display: block;
        }

        .c-react-select__control {
            &:not(.c-react-select__control--menu-is-open) {
                .c-react-select__name,
                .c-react-select__email {
                    color: $grey;
                }
            }

            &.c-react-select__control--menu-is-open {
                .c-react-select__name,
                .c-react-select__email {
                    color: $black;
                }
            }
        }

        .c-react-select__email {
            font-size: 12px;
            line-height: 14px;
        }

        .c-react-select__option {
            overflow-x: hidden;
            text-overflow: ellipsis;
            display: block;
            white-space: nowrap;
        }
    }

    &__email,
    &__name {
        display: block;
    }

    &__email {
        font-size: 12px;
        line-height: 14px;
    }

    &__option-container {
        display: block;
        overflow: hidden;
        white-space: nowrap;

        > span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    &--normal {
        .c-react-select__single-value {
            @include arialNormal;
        }
    }

    &__same-row {
        @include flexMiddle;
    }

    &__color {
        width: clamp(9px, 9px, 9px);
        height: clamp(9px, 9px, 9px);
        border-radius: 50%;
        display: inline-block;
        @include arialNormal;
        margin-right: 12px;

        &--grey {
            background: $grey;
        }

        &--yellow {
            background: $yellow;
        }

        &--purple {
            background: $purple;
        }

        &--orange {
            background: $orange;
        }

        &--light-blue {
            background: $lightBlue;
        }

        &--red {
            background: $red
            ;
        }

        &--green {
            background: $green;
        }

        &--undefined {
            display: none;
        }
    }

    &--full-height {
        .c-react-select__menu-list {
            @media screen and (min-height: 650px) {
                max-height: unset;
            }
        }
    }

    //disabled style
    &--is-disabled {
        background: $lightGrey;
        border-radius: 4px;

        .c-react-select__control--is-disabled {
            border: none !important;

            &::after {
                display: none;
            }
        }
    }
}
