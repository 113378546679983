@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.c-contact-skeleton-loader {
    &__item {
        @include skeletonLoaderDefaults;
        height: 10px;
    }

    &__medium {
        width: 150px;

        &--has-bottom {
            margin-bottom: 5px;
        }
    }

    &__small {
        width: 80px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}