@import '../../../scss/mixins';
@import '../../../scss/variables';

.details-popup {
    // max-height: 100vh;
    height: 100%;
    position: fixed;
    right: -100%;
    bottom: 0;
    top: 0;
    background-color: $white;
    box-shadow: -8px 0px 24px rgba(17, 21, 34, 0.16);
    overflow-y: scroll;
    width: 100%;
    z-index: 3;

    @include mq('from', 1281) {
        max-width: 960px;
        padding: 30px 32px;
    }

    @include mq('fromto', 769, 1281) {
        max-width: 640px;
        padding: 30px 32px 34px;
    }

    @include mq('fromto', 376, 769) {
        height: calc(100% - 72px);
        top: 72px;
    }

    @include mq('fromto', 577, 769) {
        padding: 30px 24px 26px;
    }

    @include mq('from', 577) {
        transition: all 1s ease;
    }

    @include mq('to', 577) {
        transition: all 0.8s ease;
    }

    @include mq('fromto', 376, 577) {
        padding: 30px 24px 45px;
    }

    @include mq('to', 376) {
        padding: 21px 16px 45px;
        height: calc(100% - 56px);
        top: 56px;
    }

    &__close-btn {
        background: none;
        outline: none;
        border: none;
        position: absolute;
        cursor: pointer;
        padding: 5px;
        transition: all 0.3s ease;
        width: 30px;
        height: 30px;

        @media (hover: hover) {
            &:hover {
                transform: rotate(180deg);
            }
        }

        @include mq('from', 577) {
            right: 33px;
        }

        @include mq('fromto', 376, 577) {
            right: 19px;
        }

        @include mq('from', 376) {
            top: 30px;
        }

        @include mq('to', 376) {
            top: 21px;
            right: 21px;
        }

        i {
            font-size: 18px;
            color: $black;
        }
    }

    &__basic-info {
        @include creatoBold;

        @include mq('from', 1281) {
            margin-top: 26px;
        }

        @include mq('fromto', 376, 1281) {
            margin-top: 18px;
        }

        @include mq('from', 376) {
            margin-bottom: 32px;
        }

        @include mq('to', 376) {
            margin-top: 19px;
            margin-bottom: 24px;
        }
    }

    &__part-name {
        margin-bottom: 4px;
        color: $black;
    }

    &__part-number {
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;
        color: $grey;
    }

    &__image {
        margin-bottom: 24px;
        position: relative;

        &--hide-link {
            a {
                pointer-events: none;

                i {
                    display: none;
                }
            }
        }

        a {
            display: flex;
            z-index: 1;
            border-radius: 5px;
            padding: 3px;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                inset: 0;
                opacity: 0;
                z-index: 2;
                background: rgba($black, 0.6);
                transition: $hoverTransition;
                border-radius: 5px;
            }

            @media (hover: hover) {
                &:hover {
                    &::before {
                        opacity: 1;
                    }

                    i {
                        background-color: rgba($purple-hover, 0.8);
                    }
                }
            }
        }

        i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            background-color: rgba($purple, 0.8);
            border-radius: 50%;
            transition: $hoverTransition;
            z-index: 3;

            @include mq('from', 577) {
                font-size: 38px;
                padding: 36px;
            }

            @include mq('to', 577) {
                font-size: 28px;
                padding: 26px;
            }
        }
    }

    &__normal-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;

        @include mq('from', 577) {
            max-height: 660px;
        }

        @include mq('to', 577) {
            max-height: 457px;
        }
    }

    &__fallback-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: relative;

        @include mq('from', 577) {
            max-height: 320px;
            margin: 16px 0;
        }

        @include mq('fromto', 376, 577) {
            margin: 8px 0;
        }

        @include mq('to', 577) {
            max-height: 228px;
        }

        @include mq('to', 376) {
            margin: 16px 0 8px;
        }

        &--broken-link {
            @include mq('from', 577) {
                margin-top: 0;
                margin-bottom: 32px;
            }

            @include mq('to', 577) {
                margin-bottom: 40px;
            }
        }
    }

    //*Specifications

    &__specifications {
        margin-top: 32px;
    }

    &__specifications-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mq('to', 577) {
            flex-direction: column;
        }

        &:nth-child(3) {
            div {
                @include mq('fromto', 577, 769) {
                    margin-bottom: 30px;
                }
            }
        }
    }

    &__specification {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
        margin-bottom: 32px;

        @include mq('from', 769) {
            max-width: 277px;
        }

        @include mq('fromto', 577, 769) {
            max-width: 218px;
        }

        @include mq('to', 577) {
            max-width: 100%;
        }

        &--comment {
            max-width: 100%;
            margin-bottom: 0;
            margin-top: 0;

            span {
                &:last-child {
                    font-style: italic;
                }
            }
        }

        > span {
            font-size: 14px;
            line-height: 18px;
            font-family: $secondaryFont;
            font-weight: 400;
            color: $black;

            @include mq('from', 769) {
                padding-right: 32px;
            }

            @include mq('fromto', 577, 769) {
                padding-right: 24px;
            }

            @include mq('to', 577) {
                padding-right: 0;
            }

            &:first-child {
                color: $grey;
            }

            &:nth-child(2) {
                margin-top: 8px;
            }

            &:nth-child(3) {
                font-style: italic;
            }
        }
    }

    &--show {
        @extend .details-popup;
        right: 0;
    }

    &__backdrop {
        position: fixed;
        inset: 0;
        z-index: 2;
        background: rgba($pureBlack, 0.5);
    }

    &__link-with-icon {
        @include flexMiddle;

        &:hover {
            i,
            span {
                color: $purple-hover;
            }
        }

        i,
        span {
            color: $purple;
            transition: $hoverTransition;
        }

        span {
            @include creatoBold;
        }

        i {
            margin-right: 9px;
            font-size: 13px;
        }
    }

    &__surface {
        li {
            position: relative;
            font-size: 14px;

            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: -10px;
                width: 4px;
                height: 4px;
                border-radius: 10px;
                background-color: $grey;
            }

            &:first-child {
                margin: 8px 0 0 10px;
            }

            &:not(:first-child) {
                margin: 4px 0 0 10px;
            }
        }

        span {
            margin-top: 4px;
        }
    }
}
