@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.c-invite-contact-modal {
    &__fields {
        &--2 {
            display: flex;

            > * {
                width: 100%;

                &:first-child {
                    @include mq('from', 601) {
                        margin-right: 32px;
                    }

                    @include mq('fromto', 375, 601) {
                        margin-right: 24px;
                    }

                    @include mq('to', 375) {
                        margin-right: 10px;
                    }
                }
            }
        }

        &--bottom {
            align-items: flex-end;
        }
    }

    &__field {
        margin-top: 24px;

        &--small {
            min-width: 120px;
            max-width: 120px;
        }
    }

    &__portal-roles {
        margin-top: 24px;

        @include mq('from', 601) {
            @include flexMultipleItemsInRow(24px, 0, 2);
        }

        @include mq('fromto', 375, 601) {
            @include flexMultipleItemsInRow(18px, 0, 2);
        }

        @include mq('to', 375) {
            @include flexMultipleItemsInRow(10px, 0, 2);
        }
    }

    &__label {
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        padding-bottom: 8px;
        display: block;
    }

    button {
        margin-top: 40px;
        margin-left: auto;
    }
}