@import '../../scss/variables';
@import '../../scss/mixins';

.sidebar {
    @include flexColumnBetween;
    background-color: $black;
    color: $grey;
    // height: 100vh;
    overflow-y: auto;

    @include mq('from', 1281) {
        padding: 40px 10px 0 32px;
        width: 100%;
        max-width: 264px;
    }

    @include mq('from', 769) {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
    }

    @include mq('fromto', 769, 1281) {
        width: auto;
        padding: 40px 20px 0;
        max-width: 64px;
        min-width: 64px;
        overflow-x: hidden;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    //*Sidebar header
    &__header {
        @include flexColumnBetween;
    }

    &__header-logo {
        @include mq('to', 1281) {
            margin-top: 60px;
            transform: rotate(-90deg) translateY(2px);
        }

        a {
            img {
                @include mq('to', 1281) {
                    width: 101px;
                    height: 24px;
                }
            }
        }
    }

    //*Header links
    &__header-links {
        @include flexColumnBetween;
        padding-left: 3px;

        @include mq('from', 1281) {
            margin-top: 100px;
        }

        @include mq('fromto', 769, 1281) {
            margin-top: 50px;
        }

        @include mq('to', 769) {
            margin-top: 0;
        }
    }

    //*Footer
    &__footer {
        @include flexColumn;

        @include mq('from', 577) {
            max-width: 200px;
            padding-bottom: 40px;
        }

        @include mq('to', 577) {
            max-width: 100%;
            padding-bottom: 0;
        }
    }

    //*Footer links{
    &__footer-links {
        padding-left: 3px;
        @include flexColumn;
    }

    //*Footer notification
    &__footer-notification {
        @include flexCenterMiddle;

        @include mq('from', 1281) {
            margin-bottom: 32px;
        }

        @include mq('fromto', 769, 1281) {
            margin-bottom: 36px;
        }

        @include mq('to', 769) {
            margin-bottom: 32px;
        }

        //*Notification counter
        p {
            padding: 3px 7px;
            background-color: $orange;
            height: fit-content;
            color: $white;
            border-radius: 4px;
            max-height: 24px;
            @include flexCenterMiddle;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
            @include creatoBold;

            @include mq('fromto', 769, 1281) {
                display: none;
            }

            @include mq('to', 769) {
                display: block;
            }
        }
    }

    //*User profile in sidebar
    &__footer-profile {
        @include flexBetween;
        padding-top: 24px;
        border-top: 1px solid $grey-o32;
        align-items: flex-start;

        @include mq('fromto', 769, 1281) {
            width: fit-content;
            transform: translateX(-3.5px);
        }

        @include mq('to', 769) {
            width: 100%;
            transform: translateX(0);
        }
    }

    //*Info about name and company div
    &__footer-profile-info {
        @include mq('fromto', 769, 1281) {
            display: none;
        }

        @include mq('to', 769) {
            display: block;
        }
    }

    &__footer-profile-info-short {
        h4 {
            color: $white;
        }

        @include mq('from', 1281) {
            display: none;
        }

        @include mq('fromto', 769, 1281) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background-color: $orange;
            color: $white;
            border-radius: 32px;
        }

        @include mq('to', 769) {
            display: none;
        }
    }

    //*User name
    &__footer-profile-name {
        color: $white;
        font-size: 16px;
        line-height: 20px;
    }

    //*User company
    &__footer-profile-company {
        color: $grey;
        font-size: 12px;
        line-height: 14px;
        margin-top: 4px;
    }

    &__footer-profile-menu-icon {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        margin-top: 4px;

        @include mq('from', 1281) {
            position: relative;
        }

        @include mq('fromto', 769, 1281) {
            position: absolute;
            bottom: 0px;
            left: 0px;
            padding: 6px 15px;
            border-radius: 32px;
            max-width: 32px;
        }

        @include mq('to', 769) {
            position: relative;
            padding: 6px 11px;
        }

        i {
            color: $grey;
            width: 20px;

            @include mq('from', 1281) {
                padding: 0 11px;
            }

            @include mq('fromto', 769, 1281) {
                position: absolute;
                top: -20px;
                color: transparent;
                padding: 8px 15px;
                transform: translateX(-15px);
                border-radius: 50px;
            }

            @include mq('to', 769) {
                padding: 0 11px;
            }
        }
    }

    &__footer-profile-menu-links {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: 88px;
        background-color: $white-2;
        opacity: 0;
        cursor: initial;
        pointer-events: none;
        border-radius: 4px;
        z-index: -1;

        @include mq('from', 1281) {
            width: 200px;
            padding: 18px;
            left: 32px;
            bottom: 102px;
        }

        @include mq('fromto', 769, 1281) {
            width: 100%;
            height: 88px;
            padding: 17px 7px 18px;
            left: 0;
            bottom: 58px;
        }

        @include mq('to', 769) {
            width: 100%;
            padding: 18px;
            bottom: 62px;
            left: 0;
        }

        a,
        button {
            @include creatoBold;
            font-size: 16px;
            line-height: 20px;
            color: $grey;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            text-transform: capitalize;
            text-align: center;
            display: flex;
            align-items: center;
            transition: $hoverTransition;

            @media (hover: hover) {
                &:hover {
                    color: $black;

                    i {
                        color: $orange;
                    }
                }
            }

            @include mq('fromto', 769, 1281) {
                span {
                    display: none;
                }
            }

            @include mq('to', 769) {
                span {
                    display: block;
                }
            }
        }

        button {
            width: 100%;
            padding-bottom: 8px;

            i {
                color: $red;
                font-size: 16px;
                margin-right: 20px;
                transition: $hoverTransition;

                @include mq('from', 1281) {
                    padding-left: 1px;
                }

                @include mq('to', 1281) {
                    padding-left: 1px;
                }
            }

            @media (hover: hover) {
                &:hover {
                    i {
                        color: $red;
                    }
                }
            }
        }

        a {
            margin-bottom: 8px;
            width: 100%;
            padding-bottom: 8px;

            i {
                color: $grey;
                font-size: 18px;
                margin-right: 19px;
                transition: $hoverTransition;
            }
        }

        &--show {
            @extend .sidebar__footer-profile-menu-links;
            opacity: 1;
            z-index: 1;
            transform: translateY(0);
            pointer-events: all;
        }
    }

    &__footer-profile-name,
    &__footer-profile-company {
        @include creatoBold;
    }
}
