@import '../../../scss/mixins';
@import '../../../scss/variables';
.c-fa-details-tabs-btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    @include mq('to', 577) {
        align-items: flex-start;
        flex-direction: column;
    }

    &__tabs-wrapper {
        margin-bottom: 0;
    }

    &__btn-wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @include mq('from', 376) {
            margin-bottom: 32px;
        }

        @include mq('to', 376) {
            margin-bottom: 24px;
        }
    }
}
