.c-field-error {
    padding-top: 8px;
    font-size: 14px;
    line-height: 18px;
    opacity: 0;
    animation: opacityToVisible 0.3s forwards;
    color: $red;
}

@keyframes opacityToVisible {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}