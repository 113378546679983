@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-table-list-item {
    transition: 0.3s ease;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:last-child {
        margin-bottom: 0;;
    }

    &--expanded {
        &:not(:last-child) {
            margin-bottom: 32px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &--frameworkComponents,
    &--orderComponents {
        .c-table-list-item__header {
            @include mq('from', 1280) {
                min-width: 1046px;
            }

            @include mq('to', 1280) {
                min-width: 1040px;
            }
        }
    }

    &__header {
        display: flex;
        background-color: $lightGrey;
        min-height: 92px;

        &.expanded {
            border-radius: 4px 4px 0 0;
        }

        &:not(.expanded) {
            border-radius: 4px;
        }
    }

    &__dropdown-box {
        @include flexMiddle;

        @include mq('from', 1280) {
            min-width: 76px;
            padding: 5px 24px 5px 14px;
        }

        @include mq('to', 1280) {
            min-width: 68px;
            padding: 5px 16px 5px 14px;
        }

        button {
            cursor: pointer;
            display: flex;
            border: none;
            padding: 10px;
            background: none;

            &:hover {
                color: $grey;
            }
        }
    }

    &__dropdown-action {
        transition: all 0.3s ease;

        &--rotate {
            transform: rotateX(180deg);
        }

        i {
            font-size: 8px;
        }
    }

    &__collapsable-content {
        overflow-y: hidden;

        &--frameworkComponents,
        &--orderComponents {
            @include mq('from', 1280) {
                min-width: 1046px;
            }

            @include mq('to', 1280) {
                min-width: 1040px;
            }
        }
    }

    &--orderDeliveryPopup {
        min-width: 760px;
    }
}