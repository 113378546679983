@import '../../scss/mixins';
@import '../../scss/variables';

.c-table {
    overflow-x: auto;

    &__table-info {
        @include mq('fromto', 769, 1025) {
            margin-top: 8px;
        }

        @include mq('fromto', 577, 769) {
            margin-top: 0;
        }

        @include mq('to', 577) {
            margin-top: 8px;
        }
    }

    &__year {
        @include mq('from', 769) {
            margin-bottom: 40px;
        }

        @include mq('to', 769) {
            font-size: 24px;
            margin-bottom: 16px;
        }

        &--skeleton-loader {
            display: block;
            width: 70px;
            height: 35.5px;
            @include skeletonLoaderDefaults;

            @include mq('to', 768) {
                height: 28.5px;
            }
        }
    }

    &__month {
        @include mq('from', 769) {
            margin-bottom: 32px;
        }

        @include mq('to', 769) {
            font-size: 20px;
            margin-bottom: 16px;
        }

        &--skeleton-loader {
            display: block;
            width: 65px;
            height: 28.5px;
            @include skeletonLoaderDefaults;

            @include mq('to', 768) {
                height: 24px;
            }
        }
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
