@import '../../../../../scss/mixins';
@import '../../../../../scss/variables';

.c-manage-contacts-modal {
    &__content {
        margin-top: 32px;

        button {
            margin-top: 40px;
            margin-left: auto;
        }
    }

    &__select-wrapper {
        margin-top: 24px;
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        font-family: $secondaryFont;
    }
}
