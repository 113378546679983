// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-Thin.otf") format("opentype");
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-ThinItalic.otf") format("opentype");
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-Light.otf") format("opentype");
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-LightItalic.otf") format("opentype");
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: 'Creato Display';
    src: url("../../fonts/creato-display/CreatoDisplay-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-RegularItalic.otf") format("opentype");
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: 'Creato Display Bold';
    src: url("../../fonts/creato-display/CreatoDisplay-Bold.otf") format("opentype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-BoldItalic.otf") format("opentype");
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-Medium.otf") format("opentype");
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-MediumItalic.otf") format("opentype");
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-ExtraBold.otf") format("opentype");
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Creato Display';
//     src: url("../../fonts/creato-display/CreatoDisplay-ExtraBoldItalic.otf") format("opentype");
//     font-weight: 800;
//     font-style: italic;
//     font-display: swap;
// }