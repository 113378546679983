.c-input {
    label {
        display: block;
        margin: 24px 0 0;
        font-size: 14px;
        line-height: 18px;
        color: $grey;

        input,
        textarea {
            margin-top: 8px;
        }
    }

    // basic input
    input,
    textarea {
        width: 100%;
        height: 44px;
        padding: 13px 12px;
        border-radius: 4px;
        outline: none;
        transition: 0.3s ease;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-family: $secondaryFont;
        color: $black;
        border: 2px solid $paleGrey;

        @media (hover: hover) {
            &:hover {
                border-color: $grey;
                transition: $hoverTransition;
            }
        }

        &:focus {
            border-color: $black;
        }

        &[disabled] {
            border: none;
            background: $lightGrey;
            color: $black;
        }
    }

    &__error-message {
        color: $red;
        padding-top: 8px;
        font-size: 14px;
        line-height: 18px;
        opacity: 0;

        &.active {
            animation: opacityToVisible 0.3s forwards;
        }
    }

    &__error {
        input,
        textarea {
            border-color: $red;
        }
    }

    // search input field
    &--search {
        position: relative;
        width: 100%;

        @include mq('from', 769) {
            max-width: 378px;
            margin-bottom: 32px;
        }

        @include mq('fromto', 576, 769) {
            margin-bottom: 24px;
            max-width: 348px;
        }

        @include mq('to', 576) {
            max-width: 100%;
            margin-bottom: 16px;
        }

        input,
        textarea {
            padding: 0 34px 0 38px;

            &:not(&:placeholder-shown) {
                border-color: $black;
            }
        }

        .icon-search {
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
            pointer-events: none;
            transition: all 0.3s;

            &--grey {
                color: $grey;
            }

            &--black {
                color: $black;
            }
        }
    }

    &__reset {
        position: absolute;
        right: 15px;
        outline: none;
        background: none;
        border: none;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
    }

    // textarea styles
    textarea {
        resize: none;
        width: 100%;
    }

    // input for readonly
    &--readonly {
        pointer-events: none;

        input {
            color: $grey;
        }
    }

    &__required-error {
        transition: $hoverTransition;
        color: $orange;
        font-size: 16px;
        line-height: 20px;

        &--red {
            color: $red;
        }
    }
}

@keyframes opacityToVisible {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
