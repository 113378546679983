@import '../../scss/variables';
@import '../../scss/mixins';

.c-uploaded-file {
    color: $grey;
    @include flexMiddle;
    justify-content: space-between;
    margin-top: 10px;
    cursor: auto;

    &__icon-name {
        display: flex;
    }

    &__file-name {
        font-size: 14px;
        line-height: 18px;
        padding-right: 15px;
        @include creatoBold;
    }

    &.c-uploaded-file__summary span {
        color: $grey;
    }

    i {
        font-size: 15px;
        margin-right: 9.5px;
    }

    &__summary {
        &:first-child {
            margin-top: 0;
        }
    }

    button {
        outline: none;
        background: none;
        border: none;
        width: 11px;
        cursor: pointer;
        transition: $hoverTransition;

        @media (hover: hover) {
            &:hover {
                transform: rotate(180deg);
            }
        }

        i {
            font-size: 12px;
            color: $grey;
        }
    }

    &__delete-anim {
        animation: deleteAnimation 0.3s linear;
    }
}

@keyframes deleteAnimation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
