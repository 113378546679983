.l-container {
    width: 100%;
    overflow-y: auto;
    min-height: 100vh;

    @include mq('from', 769) {
        padding: 40px 32px;
    }

    @include mq('fromto', 376, 769) {
        padding: 104px 24px 24px;
    }

    @include mq('to', 376) {
        padding: 88px 16px 16px;
    }

    // fix for when datepicker is open
    &.minHeightRequired {
        &.offersDatepicker {
            @include mq('fromto', 485, 769) {
                min-height: 834px;
            }

            @include mq('fromto', 376, 485) {
                min-height: 794px;
            }

            @include mq('to', 376) {
                min-height: 774px;
            }
        }

        &:not(.offersDatepicker) {
            @include mq('fromto', 485, 769) {
                min-height: 870px;
            }

            @include mq('fromto', 376, 485) {
                min-height: 795px;
            }

            @include mq('to', 376) {
                min-height: 770px;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;

        span {
            @include mq('to', 577) {
                display: none;
            }
        }

        a {
            @include mq('to', 375) {
                max-width: 44px;
                padding: 13px 22px;
            }

            i {
                display: none;

                @include mq('to', 375) {
                    display: block;
                    margin-left: 0;
                    font-size: 11px;
                }
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mq('to', 951) {
            flex-direction: column;
        }
    }

    &__tabs-picker {
        display: flex;

        @include mq('from', 1330, 1640) {
            position: relative;
        }

        @include mq('to', 1339) {
            flex-direction: column-reverse;
            align-items: flex-end;
        }

        @include mq('to', 951) {
            width: 100%;
            justify-content: flex-end;
        }

        &--with-select {
            flex-direction: row-reverse;

            .c-react-select {
                @include mq('fromto', 951, 1339) {
                    margin-left: 16px;
                }

                @include mq('from', 577) {
                    margin-right: 16px;
                }

                @include mq('to', 577) {
                    margin-bottom: 16px;
                }
            }

            @include mq('fromto', 577, 1339) {
                align-items: flex-start;
            }

            @include mq('to', 577) {
                flex-direction: column-reverse;
            }
        }
    }
}
