@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.c-price-dropdown {
    background: rgba($grey, 0.08);
    border-radius: 4px;
    width: 100%;
    z-index: 2;

    @include mq('fromto', 577, 1281) {
        position: absolute;
    }

    &__dropdown-button {
        position: absolute;
        top: 23px;
        right: 23px;
        height: 24px;
        width: 24px;
        border: none;
        cursor: pointer;
        @include flexCenterMiddle;
        transition: all 0.3s ease;
        background: transparent;

        &--rotate {
            transform: rotateX(180deg);
        }

        i {
            font-size: 7.41px;
        }
    }

    &__header {
        padding-top: 16px;
        position: relative;
    }

    &__expandable-container {
        overflow-y: hidden;
        transition: max-height 0.3s ease;

        &--orders {
            max-height: 227px;
        }

        &--frameworkAgreement {
            max-height: 121px;
        }

        &--hidden {
            max-height: 0;
        }
    }
}