@import '../../scss/variables';
@import '../../scss/mixins';
.navbar-link {
    text-decoration: none;
    line-height: 20px;
    font-size: 16px;
    @include creatoBold;
    color: $grey;
    display: flex;
    align-items: center;

    @include mq('from', 1281) {
        margin-bottom: 32px;
    }

    @include mq('fromto', 769, 1281) {
        width: fit-content;
        margin-bottom: 36px;
    }

    @include mq('to', 769) {
        margin-bottom: 32px;
    }

    //*Help link in sidebar footer
    &--help {
        margin-top: 0;
    }

    //*Notification link in sidebar footer
    &--notification {
        margin-bottom: 0;
    }

    //*Link icon\
    :global {
        svg {
            margin-right: 15px;
            transition: $hoverTransition;
        }
    }

    span {
        transition: $hoverTransition;
        transform: translateY(2px);

        @include mq('fromto', 769, 1281) {
            display: none;
        }

        @include mq('to', 769) {
            display: block;
        }
    }

    @media (hover: hover) {
        &:hover {
            span {
                color: $white;
            }

            :global {
                .icon-path {
                    fill: $orange;
                }
            }
        }
    }

    &:global.active {
        .icon-path {
            fill: $orange;
        }

        span {
            color: $white;
        }
    }

    &__footer-notification {
        @include flexCenterMiddle;
        margin-bottom: 32px;

        //*Notification counter
        p {
            padding: 3px 7px;
            background-color: $orange;
            height: fit-content;
            color: $white;
            border-radius: 4px;
            max-height: 24px;
            @include flexCenterMiddle;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            margin-bottom: 0;
        }
    }
}
