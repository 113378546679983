@import '../../scss/mixins';

.c-framework {
    :global {
        .c-react-select {
            @include mq('to', 576) {
                margin-bottom: 16px !important;
            }
        }
    }
}
