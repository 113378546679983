@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-table-header {
    display: flex;
    width: 100%;
    min-height: 50px;
    font-size: 14px;

    &:not(.c-table-header--no-top-radius) {
        border-radius: 4px;

        > div {
            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }
        }
    }

    &--no-top-radius {
        > div {
            &:first-child {
                border-radius: 0 0 0 4px;
            }

            &:last-child {
                border-radius: 0 0 4px 0;
            }
        }
    }

    &__orders {
        @include orders;
    }

    &__offers {
        @include offers;
    }

    &__framework {
        @include framework;
    }

    &__orderComponents {
        @include orderComponents;
    }

    &__offersSingleOrder {
        @include offerSingleOrderHeader;

        &.c-table-header--no-discount {
            &:nth-child(6) {
                display: none;
            }
        }

        &.c-table-header--no-origin {
            &:nth-child(4) {
                display: none;
            }
        }
    }

    &__offerFrameworkOrder {
        @include offerFaOrderMinWidth;

        // when no origin or discount
        &.c-table-header--no-discount {
            &.c-table-header--no-origin {
                @include offerFaOrderWithoutDiscountAndOrder;
            }
        }

        // when both discount and origin exist
        &.c-table-header--has-discount {
            &.c-table-header--has-origin {
                @include offerFaOrderWithDiscountAndOrigin;

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9) {
                    width: calc((100vw - 528px - 264px) * 0.095);
                }

                &:nth-child(10) {
                    width: calc((100vw - 528px - 264px) * 0.105);
                }
            }

            &.c-table-header--no-origin {
                @include offerFaOrderWithOriginOrDiscountHeaderAndCertificate;
            }
        }

        &.c-table-header--has-origin {
            // when only origin exists
            &.c-table-header--no-discount {
                @include offerFaOrderWithOriginOrDiscountHeaderAndCertificate;
            }
        }

        &.c-table-header--no-origin {
            &:nth-child(7) {
                display: none;
            }
        }
    }

    > div {
        width: 100%;
        @include flexCenterMiddle;
        color: $grey;
        padding: 0 8px;
        background: $lightGrey;

        &:first-child {
            padding-left: 16px;
        }

        &:last-child {
            text-align: right;

            @include mq('from', 1281) {
                padding-right: 24px;
            }

            @include mq('to', 1281) {
                padding-right: 16px;
            }
        }

        &--right {
            text-align: right;
        }

        span {
            width: 100%;
            position: relative;
            line-height: 18px;
        }

        &:global.sortable:not(.sortedBy) {
            transition: $hoverTransition;

            @media (hover: hover) {
                &:hover {
                    color: $black;
                }
            }
        }

        &:global.sortable {
            cursor: pointer;

            // TODO check if icon should be visible on hover, if true, just remove hover efect
            &:hover {
                i {
                    opacity: 1;
                }
            }

            i {
                opacity: 0;
                transition: $hoverTransition;
            }
        }

        &:global.sortedBy {
            color: $black;
            @include creatoBold;

            i {
                opacity: 1;
            }
        }
    }

    i:global.icon-chevron-down,
    i:global.icon-chevron-up {
        font-size: 4px;
        margin: 0 0 0 8px;
        bottom: 3.5px;
        position: relative;
    }

    &__order-deliveries {
        @include orderDeliveries;
    }

    &__table-invoices {
        @include orderInvoices;
    }

    &__orderDeliveryPopup {
        @include orderDeliveryPopup;
    }

    &__frameworkAgreementsOrders {
        @include frameworkAgreementsOrders;
    }

    &__frameworkComponents {
        @include frameworkComponents;
    }

    &__frameworkComponentsDetails {
        @include frameworkComponentsDetails;
    }

    &__wizardOffers {
        @include wizardOffers;

        &.c-table-header--no-discount {
            &:nth-child(10) {
                display: none;
            }
        }

        &.c-table-header--no-origin {
            &:nth-child(7) {
                display: none;
            }
        }
    }

    &__wizardSummary {
        @include wizardSummary;
    }

    &__faWizardSummary {
        @include faWizardSummary;
    }

    &__wizardFaOffers {
        @include wizardFaOffers;

        &.c-table-header--no-origin {
            &:nth-child(10) {
                display: none;
            }
        }

        &.c-table-header--no-discount {
            &:nth-child(12) {
                display: none;
            }
        }
    }

    &__offersFaWizardSummary {
        @include offersFaWizardSummary;
    }

    &__offersSingleOrderWizardSummary {
        @include offersSingleOrderWizardSummary;
    }

    &__faWizardStepOne {
        @include faWizardStepOne;
    }

    &__offersEmpty {
        @include offersEmpty;
    }
}
