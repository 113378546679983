@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-contacts {
    max-width: 646px;

    &__section {
        &:first-child {
            padding: 6px 0 24px;
        }

        &:not(:first-child) {
            border-top: 1px solid rgba($grey, 0.24);
            padding: 24px 0 32px;
        }

        &:nth-child(3) {
            padding: 24px 0;
        }

        p {
            margin-top: 8px;
            font-size: 14px;
            line-height: 18px;
            color: $black;
        }
    }
}
