@import '../../../scss/mixins';
@import '../../../scss/variables';

.skeleton-with-image {
    background-color: $lightGrey;
    width: 100%;
    padding: 28px 24px;
    max-height: 92px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    min-width: 1040px;

    @include mq('to', 1281) {
        padding: 28px 16px;
    }

    &:last-child {
        @include mq('to', 768) {
            display: none;
        }
    }

    &__wrapper {
        overflow-x: auto;
    }

    span {
        @include skeletonLoaderDefaults;

        &:not(.skeleton-with-image__image):not(.skeleton-with-image__dropdown) {
            @include mq('from', 1921) {
                max-width: 100%;
            }

            @include mq('to', 1921) {
                max-width: 90%;
            }
        }
    }

    &__image-part-name-box {
        display: flex;
        align-items: flex-start;
        min-width: 220px;

        span {
            &:not(.skeleton-with-image__image) {
                min-width: 120px;
            }
        }

        @include mq('from', 1921) {
            min-width: 22%;

            span {
                &:not(.skeleton-with-image__image) {
                    min-width: 210px;
                }
            }
        }

        @include mq('to', 1281) {
            min-width: 210px;
        }
    }

    &__label-value-wrapper {
        min-width: 126px;

        @include mq('from', 1921) {
            min-width: 10%;
        }
    }

    &__image {
        display: block;
        width: 44px;
        height: 44px;
        background-color: $inactiveGrey;
        border-radius: 4px;
        margin-right: 16px;
    }

    &__label-value {
        display: flex;
        flex-direction: column;
    }

    &__label {
        margin-bottom: 8px;
    }

    &__label,
    &__value {
        min-width: 110px;
        height: 18px;
    }

    &__dropdown-wrapper {
        min-width: 32px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__dropdown {
        width: 15px;
        height: 15px;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
