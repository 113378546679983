.c-table-item {
    @include flexBetween;
    // orders table css
    &__orders-row {
        min-width: 1317px;
    }

    &__orders {
        @include orders;

        &:nth-child(5) {
            span {
                color: $grey;

                i {
                    color: $grey;
                    font-size: 14px;
                    margin-right: 10px;
                }
            }
        }
    }

    // when linkable
    &--deliveries-link,
    &--link {
        &:nth-child(2) {
            border-top: 1px solid transparent;

            @media (hover: hover) {
                &:hover {
                    border-top: 1px solid $grey-o24;
                }
            }
        }

        @media (hover: hover) {
            &:hover {
                background: $lightGrey;
                transition: $hoverTransition;
            }
        }
    }

    &--deliveries-link {
        cursor: pointer;
    }

    &--small {
        font-size: 14px;
        line-height: 18px;
        margin-top: 4px;
    }

    &--bold {
        @include creatoBold;
    }

    //*For offers table alignment
    &__offers-row {
        min-width: 1026px;
    }

    &__offers {
        @include offers;
    }

    //framework table css
    &__framework-row {
        @include mq('from', 1024) {
            min-width: 1060px;
        }

        @include mq('to', 1024) {
            min-width: 980px;
        }
    }

    &__framework {
        @include framework;

        &:first-child {
            span {
                font-weight: 400;
                font-family: $secondaryFont;
            }
        }
    }

    &__orders,
    &__offers,
    &__framework {
        height: 72px;
    }

    &__order-deliveries,
    &__table-invoices {
        min-height: 66px;
    }

    &__offersSingleOrder,
    &__offerFrameworkOrder {
        min-height: 92px;
    }

    &:not(.hasCertificate):not(.hasErrorMessage) {
        > div {
            border-bottom: 1px solid $grey-o24;
        }
    }

    > div {
        font-size: 14px;
        line-height: 18px;
        padding: 0 8px;
        text-align: left;
        position: relative;

        &:not(.c-table-item__offersSingleOrder):not(
                .c-table-item__offerFrameworkOrder
            ):not(.c-table-item__orderComponents):not(
                .c-table-item__orderDeliveryPopup
            ):not(.c-table-item__frameworkComponents):not(
                .c-table-item__frameworkComponentsDetails
            ):not(.c-table-item__offersSingleOrderCertificates) {
            width: 100%;
        }

        &:not(.c-table-item__multiple-items):not(
                .c-table-item__frameworkComponentsDetails
            ):not(.c-table-item__wizardFaOffers):not(
                .c-table-item__offersFaWizardSummary
            ):not(.c-table-item__offersSingleOrderWizardSummary) {
            @include flexCenterMiddle;
        }

        &:not(.c-table-item__orderDeliveryPopup):not(
                .c-table-item__frameworkAgreementsOrders
            ):not(.c-table-item__frameworkComponents):first-child:not(
                .c-table-item__wizardSummary
            ):first-child:not(.c-table-item__faWizardSummary):first-child:not(
                .c-table-item__wizardFaOffers
            ):first-child:not(
                .c-table-item__offersSingleOrderWizardSummary
            ):first-child:not(.c-table-item__faWizardStepOne):first-child {
            padding-left: 16px;
            @include creatoBold;
        }

        &:is(.c-table-item__wizardOffers) {
            &:first-child {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &:is(.c-table-item__offersFaWizardSummary) {
            &:first-child {
                font-size: 16px;
                line-height: 20px;
            }
        }

        &:is(.c-table-item__wizardSummary) {
            &:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 16px;
                font-family: $secondaryFont;
            }
        }

        &:is(.c-table-item__faWizardSummary) {
            &:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 16px;
                font-family: $secondaryFont;
            }
        }

        &:is(.c-table-item__offersSingleOrderWizardSummary) {
            &:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 16px;
                font-family: $secondaryFont;
            }
        }

        &:is(.c-table-item__faWizardSummary) {
            &:last-child {
                font-size: 16px;
                line-height: 20px;
                @include creatoBold;
            }
        }

        &:is(.c-table-item__wizardFaOffers) {
            &:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 16px;
                font-family: $secondaryFont;
            }
        }
        &:is(.c-table-item__faWizardStepOne) {
            &:first-child {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                padding-left: 16px;
                font-family: $secondaryFont;
            }
        }

        &:is(.c-table-item__faWizardStepOne) {
            &:nth-child(11) {
                font-size: 16px;
                line-height: 20px;
                @include creatoBold;
            }
        }

        &:last-child {
            @include mq('from', 1281) {
                padding-right: 24px;
            }

            @include mq('to', 1281) {
                padding-right: 16px;
            }

            .c-table-item__content {
                display: flex;
                justify-content: right;
            }
        }
    }

    &__contacts {
        .c-table-item__content {
            @include overflowEllipsis;

            &.hasOverflow {
                @media (hover: hover) {
                    &:hover {
                        .c-table-item__overflow-content {
                            display: block;
                            opacity: 3;
                            pointer-events: all;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }

    &__content {
        display: block;
        width: 100%;
        word-break: break-word;

        > span {
            position: relative;
            color: $black;
        }
    }

    &__status {
        @include creatoBold;

        > span {
            width: 9px;
            height: 9px;
            position: absolute;
            left: -21px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
        }

        &--active {
            background: $purple;
        }

        &--inactive {
            background: $grey;
        }
    }

    // hover data when overflow exists
    &__overflow-content {
        position: absolute;
        left: calc(100% - 160px);
        top: 40px;
        padding-top: 12px;
        opacity: 0;
        z-index: -2;
        transition: opacity 0.3s ease;
        min-width: 273px;
    }

    &__overflow-data {
        max-width: 320px;
        box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);
        border-radius: 4px;
        padding: 5.5px;
        background: $white;
        white-space: pre-line;
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        overflow-wrap: break-word;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 28px;
            top: 0em;
            left: 50%;
            box-sizing: border-box;
            border: 5px solid;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -1px 1px 1px 0 rgba(17, 21, 34, 0.08);
        }
    }

    // for when one cell contains multiple items
    &__multiple-items {
        &:last-child {
            padding-right: 0 !important;
        }

        &.c-table-item__offersSingleOrder {
            display: block;
            padding: 0;
        }
    }

    &__container {
        @include flexMiddle;
        word-break: break-word;

        &--material {
            display: block;
        }

        &--multiple {
            flex-direction: column;
        }

        &--quantity {
            span {
                span {
                    margin-left: 4px;
                }
            }
        }

        &--link-with-icon {
            a {
                @include flexMiddle;
                @include creatoBold;
                color: $purple;
                @include overflowEllipsis;

                @media (hover: hover) {
                    &:hover {
                        color: $purple-hover;
                    }
                }

                i {
                    margin-right: 8px;
                }

                .icon-expand {
                    font-size: 13.5px;
                }

                .icon-file-2 {
                    font-size: 15px;
                }
            }
        }

        &--right {
            justify-content: flex-end;
        }
    }

    &__image {
        min-width: 44px;
        max-width: 44px;
        height: 44px;
        background: $lightGrey;
        margin-right: 16px;
        border-radius: 4px;
        @include flexCenterMiddle;
        cursor: pointer;
        transition: $hoverTransition;
        position: relative;
        z-index: 1;
        overflow: hidden;

        .c-table-item__frameworkComponents-list &,
        .c-table-item__orderComponents-list & {
            background: $lightGrey3;
        }

        img {
            width: 100%;
            height: auto;
            z-index: 1;
            object-fit: contain;
            transform: scale(1.4);
        }

        &--scale-1 {
            img {
                transform: scale(1);
            }
        }

        &.normalCursor {
            cursor: unset;
        }
    }

    &__image-overlay {
        position: absolute;
        inset: 0;
        opacity: 0;
        z-index: 2;
        @include flexCenterMiddle;
        background: rgba($black, 0.4);
        transition: $hoverTransition;

        i {
            transition: $hoverTransition;
            color: $white;
        }
    }

    &--with-multiple {
        > div {
            margin-top: 24px;
        }
    }

    &--grey {
        color: $grey;
        @include arialNormal;
    }

    &:not(.c-table-item--with-multiple) {
        .c-table-item__offersSingleOrder {
            .c-table-item__single-item {
                margin-top: 24px !important;
                display: flex;
                align-items: center;
            }
        }
    }

    &__offersEmpty {
        @include offersEmpty;
    }

    &__offersSingleOrder {
        @include offersSingleOrder;
        padding-bottom: 24px;

        .c-table-item--with-multiple & {
            align-items: flex-start !important;
            margin-top: 0;
            padding-top: 24px;
        }

        .c-table-item__single-item {
            margin: 0 !important;

            &:first-child {
                min-width: 168px;

                @include mq('from', 1280) {
                    width: calc((100vw - 531px - 64px) * 0.18045112782 - 100px);
                }

                @include mq('fromto', 769, 1280) {
                    width: calc((100vw - 64px - 64px) * 0.18045112782 - 100px);
                }

                @include mq('fromto', 376, 769) {
                    width: calc((100vw - 64px) * 0.18045112782 - 100px);
                }

                @include mq('to', 376) {
                    width: calc((100vw - 32px) * 0.18045112782 - 100px);
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                min-width: 152px;

                @include mq('from', 1280) {
                    width: calc(
                        (100vw - 531px - 64px) * 0.142857142857 - 100px
                    );
                }

                @include mq('fromto', 769, 1280) {
                    width: calc((100vw - 64px - 64px) * 0.142857142857 - 100px);
                }

                @include mq('fromto', 376, 769) {
                    width: calc((100vw - 64px) * 0.142857142857 - 100px);
                }

                @include mq('to', 376) {
                    width: calc((100vw - 32px) * 0.142857142857 - 100px);
                }
            }

            &:nth-child(4) {
                min-width: 132px;

                @include mq('from', 1280) {
                    width: calc(
                        (100vw - 531px - 64px) * 0.124060150376 - 100px
                    );
                }

                @include mq('fromto', 769, 1280) {
                    width: calc((100vw - 64px - 64px) * 0.124060150376 - 100px);
                }

                @include mq('fromto', 376, 769) {
                    width: calc((100vw - 64px) * 0.124060150376 - 100px);
                }

                @include mq('to', 376) {
                    width: calc((100vw - 32px) * 0.124060150376 - 100px);
                }
            }

            &:nth-child(5) {
                min-width: 100px;

                @include mq('from', 1280) {
                    width: calc(
                        (100vw - 531px - 64px) * 0.124060150376 - 100px
                    );
                }

                @include mq('fromto', 769, 1280) {
                    width: calc((100vw - 64px - 64px) * 0.124060150376 - 100px);
                }

                @include mq('fromto', 376, 769) {
                    width: calc((100vw - 64px) * 0.124060150376 - 100px);
                }

                @include mq('to', 376) {
                    width: calc((100vw - 32px) * 0.124060150376 - 100px);
                }
            }

            &:first-child,
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5) {
                padding: 0 8px 0 8px;

                > div {
                    justify-content: flex-start;
                }
            }

            &:last-child {
                min-width: 210px;

                @include mq('from', 1281) {
                    padding: 0 24px 0 8px;
                    width: calc(
                        (100vw - 531px - 64px) * 0.169172932331 - 100px
                    );
                }

                @include mq('to', 1281) {
                    padding: 0 16px 0 8px;
                }

                @include mq('fromto', 769, 1281) {
                    width: calc((100vw - 64px - 64px) * 0.169172932331 - 100px);
                }

                @include mq('fromto', 376, 769) {
                    width: calc((100vw - 64px) * 0.169172932331 - 100px);
                }

                @include mq('to', 376) {
                    width: calc((100vw - 32px) * 0.169172932331 - 100px);
                }
            }
        }

        .c-table-item__single-row-with-multiple {
            &:not(:last-child) {
                border-bottom: 1px solid $grey-o24;
                padding-bottom: 25px;
            }

            &:last-child {
                margin-bottom: 24px;
            }
        }

        .c-table-item--with-multiple & {
            .c-table-item__single-row-with-multiple {
                & > div {
                    @include flexMiddle;
                }
            }
        }

        .c-table-item--no-discount {
            &.c-table-item__single-item {
                &:nth-child(5) {
                    display: none;
                }
            }
        }

        .c-table-item--no-origin {
            &.c-table-item__single-item {
                &:nth-child(3) {
                    display: none;
                }
            }
        }
    }

    &__offerFrameworkOrder {
        margin-top: 24px;

        &.c-table-item__3:not(.certificate) {
            align-items: flex-start !important;
        }

        &.c-table-item__multiple-items
            > .c-table-item__single-row-with-multiple,
        &.c-table-item__3 {
            padding-bottom: 24px;
        }

        &.c-table-item__multiple-items {
            padding-left: 0;
        }

        &:first-child {
            min-width: 190px;
        }

        &:nth-child(2) {
            min-width: 116px;
        }

        .c-table-item__single-row-with-multiple {
            &:not(:first-child) {
                margin-top: 24px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $grey-o24;
            }

            .c-table-item__single-item {
                padding-left: 8px;

                &:first-child,
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    min-width: 109px;
                }

                &:nth-child(3) {
                    min-width: 110px;

                    span {
                        color: $grey;
                    }
                }

                &:nth-child(8) {
                    min-width: 192px;
                    padding-right: 24px;
                }

                &:not(:last-child) {
                    padding-right: 8px;
                    margin: 0;

                    .c-table-item__content {
                        justify-content: flex-start;
                    }
                }

                &.c-table-item--no-discount {
                    &:nth-child(7) {
                        display: none;
                    }
                }

                &.c-table-item--no-origin {
                    &:nth-child(5) {
                        display: none;
                    }
                }
            }
        }

        .c-table-item__container--multiple {
            align-items: flex-start;

            > span {
                &:not(:first-child):not(.c-table-item__price-display) {
                    margin-top: 16px;
                }
            }
        }

        // when no discount
        &.c-table-item--no-discount {
            &.c-table-item--no-origin {
                @include offerFaOrder;

                .c-table-item__single-row-with-multiple {
                    .c-table-item__single-item {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(6) {
                            width: calc((100vw - 528px - 264px) * 0.115);
                        }

                        &:last-child {
                            width: calc((100vw - 528px - 264px) * 0.13);
                        }
                    }
                }
            }

        }

        &.noDiscount {
            &.noOrigin {
                min-width: 732px;

                .c-table-item__single-row-with-multiple {
                    .c-table-item__single-item {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(6) {
                            width: calc((100vw - 528px - 264px) * 0.115);
                        }

                        &:last-child {
                            width: calc((100vw - 528px - 264px) * 0.13);
                        }
                    }
                }
            }
        }

        // when discount exists
        &.c-table-item--has-discount {
            // when only discount exists
            &:not(.c-table-item--has-origin) {
                &:nth-child(1) {
                    width: calc((100vw - 528px - 264px) * 0.16);
                    flex: 1;
                }

                &:nth-child(2) {
                    width: calc((100vw - 528px - 264px) * 0.12);
                    flex: 1;
                }
            }

            // when both discount and origin exist
            &.c-table-item--has-origin {
                @include offerFaOrderWithDiscountAndOrigin;
            }
        }

        &.hasDiscount {
            // when only discount exists
            &:not(.hasOrigin) {
                min-width: calc((100vw - 528px - 264px) * 0.7);

                .c-table-item__single-row-with-multiple {
                    .c-table-item__single-item {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            width: calc((100vw - 528px - 264px) * 0.1);
                        }

                        &:last-child {
                            width: calc((100vw - 528px - 264px) * 0.12);
                        }
                    }
                }
            }

            // when both discount and origin exist
            &.hasOrigin {
                min-width: 732px;

                .c-table-item__single-row-with-multiple {
                    .c-table-item__single-item {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            width: calc((100vw - 528px - 264px) * 0.095);
                        }

                        &:last-child {
                            width: calc((100vw - 528px - 264px) * 0.105);
                        }
                    }
                }
            }
        }

        // when origin exists
        &.c-table-item--has-origin {
            // when offer framework order has no discount
            &.c-table-item--no-discount {
                @include offerFaOrderWithOriginOrDiscount;
            }
        }

        // when offer framework order has origin
        &.hasOrigin {
            &.noDiscount {
                min-width: 732px;

                .c-table-item__single-row-with-multiple {
                    .c-table-item__single-item {
                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            width: calc((100vw - 528px - 264px) * 0.1);
                        }

                        &:last-child {
                            width: calc((100vw - 528px - 264px) * 0.12);
                        }
                    }
                }
            }
        }

        // certificate
        &.certificate {
            @include offerFaOrderMinWidth;

            &:first-child {
                .c-table-item__content {
                    padding-left: 60px;
                    font-size: 16px;
                }
            }

            &.c-table-item--no-discount {
                @include offerFaOrderWithoutDiscountAndOrder;

                // if has origin
                &.c-table-item--has-origin {
                    @include offerFaOrderWithOriginOrDiscountHeaderAndCertificate;
                }

                &:nth-child(9) {
                    display: none !important;
                }
            }

            // if no origin {
            &.c-table-item--no-origin {
                &:nth-child(7) {
                    display: none !important;
                }
            }

            &.c-table-item--has-discount {
                &.c-table-item--no-origin {
                    @include offerFaOrderWithOriginOrDiscountHeaderAndCertificate;
                }
            }
        }
    }

    &__offerFrameworkOrder,
    &__offersSingleOrder,
    &__orderComponents-list,
    &__frameworkComponents-list,
    &__frameworkComponentsDetails {
        &:first-child {
            cursor: pointer;

            @media (hover: hover) {
                &:hover {
                    .c-table-item__image {
                        background: rgba($black, 0.4);
                    }

                    .c-table-item__image-overlay {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__offerFrameworkOrder,
    &__offersSingleOrder,
    &__orderComponents-list,
    &__frameworkComponents-list,
    &__frameworkComponentsDetails,
    &__faWizardSummary {
        .c-table-item__content {
            text-overflow: unset;
            white-space: unset;
            overflow: unset;
        }

        .c-table-item__single-row-with-multiple {
            display: flex;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    &__part-name {
        font-size: 16px;
    }

    // price item
    &__price {
        align-items: flex-end !important;

        &.calculationRuning {
            .c-table-item__price-status {
                background: linear-gradient(
                    to right,
                    $lightGrey 42.77%,
                    $green 100%
                );
            }
        }

        &.DataValidation {
            .c-table-item__price-status {
                background: linear-gradient(
                    to right,
                    $lightGrey 42.77%,
                    $yellow 100%
                );
            }
        }

        &.IncompleteData {
            .c-table-item__price-status {
                background: linear-gradient(
                    to right,
                    $lightGrey 42.77%,
                    $red 100%
                );
            }
        }

        &--wizardOffers {
            display: flex;
            flex-direction: row;
            align-items: center !important;
        }

        &--wizardSummary {
            @extend .c-table-item__price--wizardOffers;
        }

        .c-table-item--no-discount & {
            margin-bottom: 0;
        }
    }

    &__price-status {
        width: 120px;
        height: 4px;
        background-color: $lightGrey;
        border-radius: 2px;

        &.hasTargetPrice {
            margin: 0 0 12px 0 !important;
        }
    }

    &__price-display {
        color: $grey;
        text-align: right;
        margin-top: 0;

        span {
            margin-left: -3px;
            margin-right: 2px;
        }

        &--wizardOffers {
            display: none;
        }

        &--wizardSummary {
            @extend .c-table-item__price-display--wizardOffers;
        }
    }

    &__delete-btn-wizard {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        transition: $hoverTransition;
        margin: 0;
        min-width: 24px;
        max-width: 24px;
        height: 24px;

        @media (hover: hover) {
            &:hover {
                transform: rotate(180deg);
            }
        }

        i {
            font-size: 14px;
            color: $grey;
        }
    }

    &__price-comment {
        margin-bottom: 4px;
        display: flex;
        align-items: center;

        .icon-info {
            font-size: 20px;
            line-height: 20px;

            @media (hover: hover) {
                &:hover + .c-table-item__price-hover-container {
                    opacity: 1;
                }
            }
        }
    }

    &__price-icon {
        position: relative;
        margin-right: 10px;
    }

    &__price-comment-text {
        @include creatoBold;
    }

    &__price-hover-container {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
        max-width: 220px;
        top: 29px;
        opacity: 0;
        transition: $hoverTransition;

        @media (hover: none) {
            cursor: pointer;
        }

        &.active {
            opacity: 1;
        }
    }

    &__price-hover-text {
        box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);
        border-radius: 4px;
        padding: 8px;
        background: $white;
        white-space: pre-line;
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        overflow-wrap: break-word;
        color: $grey;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 28px;
            top: 0em;
            left: calc(50% - 20px);
            box-sizing: border-box;
            border: 5px solid;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -1px 1px 1px 0 rgba(17, 21, 34, 0.08);
        }
    }

    &__price-note {
        font-size: 12px;
        font-style: italic;
        line-height: 14px;
        margin-bottom: 12px;
        color: $grey;
    }

    &__total-price {
        @include creatoBold;
        // font-size: 16px;
        // line-height: 20px;
        margin-bottom: 4px;
        @include flexMiddle;

        &:not(.c-table-item__total-price--has-discount) {
            font-size: 16px;
            line-height: 20px;
        }

        &--price-before-discount {
            margin-right: 5px;
            text-decoration: line-through;
            color: $grey;
        }

        &--wizardOfferSingleOrder,
        &--wizardOffers {
            display: flex;
            flex-direction: column-reverse;

            .c-table-item__total-price--price-before-discount {
                font-size: 14px;
                margin-right: 0;
            }

            &:not(.c-table-item__total-price--has-discount) {
                margin-bottom: 0;
            }
        }

        &--wizardSummary {
            @extend .c-table-item__total-price--wizardOffers;
        }

        &--wizardOfferSingleOrder {
            margin-bottom: 0;
        }
    }

    &__total-net-price {
        font-size: 16px;
        line-height: 20px;

        &--red {
            color: $red;
        }
    }

    // order-deliveries table css
    &--deliveries-link {
        @include mq('from', 1281) {
            min-width: 936px;
        }

        @include mq('to', 1281) {
            min-width: 712px;
        }
    }

    &__order-deliveries {
        @include orderDeliveries;

        .c-table-item__container--multiple {
            align-items: flex-start;
        }
    }

    &__table-invoices {
        @include orderInvoices;

        &:nth-child(3) {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .c-table-item__single-row-with-multiple {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 8px 0;
        }
    }

    &__orderComponents {
        @include orderComponents;

        &:nth-child(3) {
            .c-table-item__container {
                flex-direction: row;
            }
        }

        &:nth-child(4) {
            .c-table-item__container {
                justify-content: flex-end;
            }
        }

        &.c-table-item__5 {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }

    &__pdf-file {
        position: relative;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }

        &:hover {
            .c-table-item__pdf-file-full-name {
                opacity: 1;
            }
        }
    }

    &__pdf-file-full-name {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        background-color: $white;
        padding: 8px;
        border-radius: 2px;
        color: $purple;
        z-index: 2;
        min-width: 227px;
        top: 25px;
        left: 25px;
        box-shadow: -8px 0px 24px rgba(17, 21, 34, 0.16);
        text-align: left;
        transition: opacity 0.4s ease;
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            left: 35px;
            box-sizing: border-box;
            border: 5px solid;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            top: 0;
            transform: rotate(135deg);
            box-shadow: -1px 1px 1px 0 rgba(17, 21, 34, 0.08);
        }
    }

    &__pdf-download {
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        display: block;
        color: $purple;
        @include creatoBold;
        white-space: nowrap;
        transition: $hoverTransition;

        @media (hover: hover) {
            &:hover {
                color: $purple-hover;
            }
        }

        i {
            font-size: 15px;
            margin-right: 9.5px;
            position: relative;
            top: 1px;
        }
    }

    &__orderDeliveryPopup {
        @include orderDeliveryPopup;

        &.c-table-item__5 {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .c-table-item__content {
            overflow: auto;
            text-overflow: none;
            white-space: unset;
        }
    }

    &__frameworkAgreementsOrders,
    &__frameworkComponents {
        @include frameworkAgreementsOrders;

        &:first-child {
            min-height: 20px;

            .c-table-item__content {
                overflow: visible;
            }
        }

        .icon-checkmark-with-green-bg {
            font-size: 20px;
        }
    }

    &__date-status {
        i {
            @include flexCenterMiddle;
            min-height: 21px;
            min-width: 21px;
        }
    }

    &__frameworkComponents {
        @include frameworkComponents;
    }

    &__frameworkComponentsDetails {
        @include frameworkComponentsDetails;

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
            pointer-events: none;
        }

        &:nth-child(5) {
            span {
                color: $grey;
            }
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            .c-table-item__container {
                align-items: unset;

                > span {
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }

    &__link {
        a {
            @include creatoBold;
            color: $purple;

            @media (hover: hover) {
                &:hover {
                    color: $purple-hover;
                }
            }

            i {
                margin-right: 8px;
            }
        }
    }

    &__wizardOfferSingleOrder,
    &__offersSingleOrderCertificates,
    &__offersWizzardComponents {
        &.c-table-item__row-item {
            padding-top: 24px;
            padding-bottom: 24px;

            .c-table-item__content {
                overflow: auto;
                text-overflow: unset;
                white-space: unset;
                word-break: break-word;
            }
        }
    }

    &__offersSingleOrderCertificates {
        @include offerSingleOrderHeader;
        min-height: 92px;

        &:first-child {
            > .c-table-item__content {
                padding-left: 60px;

                > span {
                    font-size: 16px;
                }
            }
        }

        &.c-table-item--no-discount {
            &:nth-child(6) {
                display: none !important;
            }
        }

        &.c-table-item--no-origin {
            &:nth-child(4) {
                display: none !important;
            }
        }
    }

    &__wizardOfferSingleOrder,
    &__offersWizzardComponents {
        @include wizardOffers;

        &:nth-child(2) {
            overflow-x: visible;

            &.c-table-item__row-item {
                &.c-table-item__wizardOfferSingleOrder,
                &.c-table-item__offersWizzardComponents {
                    justify-content: flex-start;
                }
            }

            .c-table-item__content {
                justify-content: flex-start;

                @include mq('from', 1900) {
                    min-width: 18.5vw;
                }

                @include mq('fromto', 1440, 1900) {
                    min-width: 16vw;
                }

                @include mq('to', 1440) {
                    min-width: 220px;
                }
            }
        }

        &:nth-child(11) {
            .c-table-item__container {
                &.c-table-item__price {
                    &.c-table-item__container--multiple {
                        display: flex;
                    }
                }
            }
        }
    }

    &__wizardOfferSingleOrder {
        &:nth-child(2) {
            display: block !important;
        }
    }

    &__wizardOffers {
        @include wizardOffers;
        min-height: 93px;

        &.c-table-item__row-item {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &:nth-child(11) {
            .c-table-item__content {
                display: flex;
                justify-content: flex-end;
            }

            .c-table-item__total-price {
                align-items: flex-end;
            }
        }

        .c-table-item__content {
            overflow: visible;
        }

        &:nth-child(8) {
            .c-react-select__control {
                background-color: transparent !important;
                border: 2px solid $grey-o32 !important;
                text-align: center;
                transition: border-color 0.4s ease !important;

                @media (hover: hover) {
                    &:hover {
                        border-color: $grey !important;
                    }
                }

                &::after {
                    right: 13px;
                    top: 12px;
                }
            }

            .c-react-select__control--menu-is-open {
                border: 2px solid $black !important;
            }

            .c-react-select__option {
                justify-content: center !important;
            }
        }
    }

    &__wizardSummary {
        @include wizardSummary;
        min-height: 90px;
    }

    &__faWizardSummary {
        @include faWizardSummary;
        min-height: 84px;
        padding-top: 24px !important;
        padding-bottom: 24px !important;
    }

    &__wizardFaOffers {
        @include wizardFaOffers;

        &:nth-child(12) {
            @include creatoBold;
            font-size: 16px;
            line-height: 20px;
        }

        &.c-table-item--no-origin {
            &:nth-child(10) {
                display: none;
            }
        }

        &.c-table-item--no-discount {
            &:nth-child(12) {
                display: none;
            }
        }

        &.certificate {
            &:nth-child(2) {
                .c-table-item__container {
                    min-width: 220px;
                    width: calc(100vw * 0.138);
                }
            }
        }
    }

    &__offersFaWizardSummary {
        @include offersFaWizardSummary;

        &.c-table-item__9 {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &:nth-child(9) {
            @include creatoBold;
            font-size: 16px;
            line-height: 20px;
        }

        &.certificate {
            &:nth-child(1) {
                .c-table-item__container {
                    min-width: 230px;

                    @include mq('from', 1551) {
                        width: calc(
                            (100vw - 576px - 64px - 64px - 31px) * 0.23
                        );
                    }

                    @include mq('to', 1551) {
                        width: calc((100vw - 64px - 64px - 31px) * 0.23);
                    }
                }
            }
        }
    }

    &__offersSingleOrderWizardSummary {
        @include offersSingleOrderWizardSummary;

        &.c-table-item__9 {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &.certificate {
            &:first-child {
                .c-table-item__container {
                    min-width: 250px;

                    @include mq('from', 1551) {
                        width: calc(
                            (100vw - 576px - 64px - 64px - 31px) * 0.23
                        );
                    }

                    @include mq('to', 1551) {
                        width: calc((100vw - 64px - 64px - 31px) * 0.23);
                    }
                }
            }
        }
    }

    &__faWizardStepOne {
        @include faWizardStepOne;

        &.c-table-item__12 {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &:nth-child(10) {
            .c-table-item__container {
                display: flex;
                justify-content: flex-end;

                div {
                    max-width: 95%;
                    position: relative;
                }

                input {
                    max-width: 100%;
                    height: 44px;
                    padding: 13px 45px 13px 13px;
                    border: 2px solid $grey-o24;
                    border-radius: 4px;
                    transition: $hoverTransition;
                    text-align: right;
                    color: $black;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    font-family: $secondaryFont;

                    @media (hover: hover) {
                        &:hover {
                            border-color: $grey;
                        }
                    }
                }

                span {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $black;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 400;
                    font-family: $secondaryFont;
                }
            }
        }

        &:nth-child(11) {
            .c-table-item__container {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    // error message for status
    &__error-message-container {
        &:last-child {
            &:not(.hasCertificate) {
                border-bottom: 1px solid $grey-o24;
            }
        }

        &.hasDiscount {
            min-width: 836px;
        }

        &:not(.hasDiscount) {
            min-width: 704px;
        }
    }

    &__error-message {
        background: $errorRed;
        padding: 26px;
        border-radius: 4px;
        @include flexMiddle;

        &:not(:last-child) {
            margin: 0 16px 14px;
        }

        &:last-child {
            margin: 0 16px 24px;
        }

        .icon-warning {
            color: $red;
            font-size: 20px;
            margin-right: 16px;
        }
    }

    &__error-message-text {
        font-size: 14px;
        line-height: 18px;
        color: $black;
    }

    // when there is requested material
    &__has-requested-material {
        > .c-table-item__container {
            @include creatoBold;

            &:first-child {
                color: $orange;
            }

            &:not(:first-child) {
                margin-top: 4px;
                text-decoration: line-through;
                color: $grey;
            }
        }

        > div {
            &:not(:first-child) {
                font-size: 12px;
                line-height: 14px;
            }
        }

        .c-table-item__material-comment {
            color: $grey;
        }
    }

    &__material-comment {
        font-style: italic;
        font-size: 12px;
        margin-top: 4px;
    }

    &__origin-country {
        @include creatoBold;
    }
}
