@import '../../scss/mixins';
@import '../../scss/variables';

.wizzard {
    width: 100%;
    height: 100%;
    background-color: $white;
    position: fixed;
    inset: 0;
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;

    &__body {
        background-color: $white;

        @include mq('from', 1281) {
            padding: 0 64px 32px;
        }

        @include mq('fromto', 769, 1281) {
            padding: 0 32px 32px;
        }

        @include mq('fromto', 376, 769) {
            padding: 0 24px 24px;
        }

        @include mq('to', 376) {
            padding: 0 16px 16px;
        }

        &--order-details {
            margin: 0 auto;

            @include mq('from', 1281) {
                max-width: 1920px;
                padding-bottom: 80px;
            }

            @include mq('to', 1281) {
                padding-bottom: 120px;
                max-width: 792px;
            }
        }

        &--summary,
        &--components {
            @include mq('from', 768) {
                padding-bottom: 120px;
            }

            @include mq('to', 768) {
                padding-bottom: 100px;
            }
        }

        h3 {
            color: $black;
            margin-top: 8px;
        }
    }

    &__step-1-heading {
        margin-bottom: 32px;
    }

    &__offer-num {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        font-family: 'Creato Display';
    }
}
