@import '../../../../scss/mixins';

.c-contact-persons {
    &__contact {
        margin-top: 24px;
    }

    &__one-row {
        @include mq('from', 768) {
            @include flexMultipleItemsInRow(24px, 0px, 2);
        }
    }
}
