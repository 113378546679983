@import '../../../scss/mixins';

.c-details-right-side {
    flex: 1;

    @include mq('from', 1281) {
        overflow-y: auto;
        max-width: calc(100vw - 264px - 528px);
    }

    @include mq('from', 769) {
        padding: 54px 32px;
    }

    @include mq('fromto', 376, 769) {
        padding: 34px 24px;
    }

    @include mq('to', 376) {
        padding: 34px 16px;
    }
}
