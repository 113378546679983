@import '../../scss/variables';
@import '../../scss/mixins';

.c-section-header-wrapper {
    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }

    &__action {
        position: relative;
    }

    &__edit-button {
        margin-left: 8px;
        @include buttonReset;
        width: clamp(16px, 16px, 16px);
        height: clamp(16px, 16px, 16px);
        @include flexCenterMiddle;
        pointer-events: unset;

        &:not(.c-section-header-wrapper__edit-button--disabled) {
            @media (hover: hover) {
                &:hover {
                    :global {
                        .icon-path {
                            fill: $purple;
                        }
                    }
                }
            }
        }

        &--disabled {
            :global {
                .icon-path {
                    fill: rgba($grey, 0.32);
                }
            }

            @media (hover:hover) {
                &:hover {
                    & + * {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }

    p {
        font-size: 14px;
        line-height: 18px;
    }
}
