@import '../../../scss/variables';
@import '../../../scss/mixins';
.c-language {
    @include mq('from', 769) {
        padding-bottom: 70px;
    }

    @include mq('fromto', 577, 769) {
        padding-bottom: 90px;
    }

    @include mq('to', 577) {
        padding-bottom: 45px;
    }

    &__actions {
        width: 100%;
        display: flex;

        @include mq('to', 577) {
            flex-direction: column;
        }
    }

    &__save-btn {
        @include mq('from', 577) {
            margin-left: 24px;
        }

        @include mq('to', 577) {
            margin-top: 20px;
            width: 100%;
            margin-left: 0;
        }
    }
}
