@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.c-register {
    height: 100%;
    @include flexColumnBetween;

    h1 {
        @include mq('from', 768) {
            margin-bottom: 40px;
        }

        @include mq('to', 768) {
            margin-bottom: 16px;
        }

        @include mq('to', 577) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    &__requirements {
        margin: 24px 0 40px;
    }

    &__requirement {
        position: relative;
        padding-left: 21px;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px;
        color: $grey;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $grey;
        }

        &.valid {
            &::before {
                background: $green;
            }
        }

        &.invalid {
            &::before {
                background: $red;
            }
        }
    }

    &__requirement-container {
        margin-left: 34px;
    }

    &__not-member {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $grey;

        @include mq('from', 577) {
            margin-top: 32px;
        }

        @include mq('to', 577) {
            margin-top: 16px;
        }

        a {
            text-decoration: underline;
            color: $grey;

            @media (hover: hover) {
                &:hover {
                    color: $black;
                }
            }
        }
    }
}