@import '../../scss/variables';
@import '../../scss/mixins';

.c-login-left {
    min-width: 50%;
    position: relative;
    background: $black;
    @include flexCenter;

    @include mq('from', 1281) {
        width: 50%;
    }

    @include mq('to', 1281) {
        width: 100%;
    }

    @include mq('from', 1024) {
        overflow: hidden;
    }

    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        left: 0;
        height: calc(100vh - 120px - 120px - 47px);
        background: url('../../images/robot-hand.webp');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom left;
        z-index: 2;

        @include mq('from', 1436) {
            width: calc(50vw - 100px);
        }

        @include mq('fromto', 1336, 1436) {
            width: calc(50vw - 50px);
        }

        @include mq('from', 1336) {
            bottom: -50px;
        }

        @include mq('fromto', 1024, 1336) {
            width: calc(50vw - 32px);
            bottom: -30px;
        }

        @include mq('to', 1024) {
            display: none;
        }
    }

    &__container {
        @include mq('from', 1600) {
            padding: 120px 130px 60px 206px;
        }

        @include mq('from', 1281) {
            width: calc(541px + 203px + 216px);
        }

        @include mq('fromto', 1281, 1600) {
            padding: 120px 64px 60px;
        }

        @include mq('fromto', 769, 1281) {
            padding: 120px 10px 60px 20px;
        }

        @include mq('fromto', 577, 1281) {
            width: 100%;
        }

        @include mq('fromto', 577, 769) {
            padding: 41px 24px;
        }

        @include mq('to', 577) {
            width: calc(541px + 203px + 216px);
        }

        @include mq('fromto', 376, 577) {
            padding: 41px 24px;
            max-height: 154px;
        }

        @include mq('to', 376) {
            padding: 41px 16px;
        }

        a {
            @include mq('to', 768) {
                img {
                    width: 101px;
                    height: 24px;
                }
            }
        }

        h1 {
            color: $white;
            font-weight: 800;
            margin-bottom: 0;
            width: fit-content;

            @include mq('from', 768) {
                font-size: 60px;
                line-height: 64px;
                max-width: 546px;
                margin-top: 16px;
            }

            @include mq('to', 768) {
                font-size: 30px;
                line-height: 36px;
                max-width: 100%;
                margin-top: 13px;
            }
        }
    }
}
