@import '../../scss/variables';

.Loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // height: 100vh;
}

.Loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    transform: translateY(-50%);
    margin: 8px;
    border: 8px solid $orange;
    border-radius: 50%;
    animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $orange transparent transparent transparent;
}

.Loader div:nth-child(1) {
    animation-delay: -0.45s;
}

.Loader div:nth-child(2) {
    animation-delay: -0.3s;
}

.Loader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
