@import '.,/../../../../scss/variables';
@import '.,/../../../../scss/mixins';

.c-login {
    width: 100%;
    height: 100%;

    &__title {
        margin-bottom: 16px;
        font-weight: 700;

        @include mq('to', 577) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    p,
    a {
        color: $grey;
    }

    &__description {
        color: $grey;

        @include mq('from', 577) {
            padding-bottom: 16px;
        }

        @include mq('to', 577) {
            padding-bottom: 0;
        }

        span {
            font-weight: 700;
        }
    }

    &__description-report-btn {
        color: rebeccapurple;
        text-decoration: underline;
    }

    &__error-code {
        margin-left: 5px;
    }

    &__button-link {
        outline: none;
        background: none;
        border: none;
        text-align: left;
        color: $grey;
        display: block;
        cursor: pointer;
        transition: $hoverTransition;
        text-decoration: underline;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        @include mq('from', 577) {
            margin: 8px 0 40px;
        }

        @include mq('to', 577) {
            margin: 8px 0 24px;
        }

        @media (hover: hover) {
            &:hover {
                color: $black;
            }
        }
    }

    a {
        @media (hover: hover) {
            &:hover {
                color: $black;
            }
        }
    }

    &__not-member {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $grey;

        @include mq('from', 577) {
            margin-top: 32px;
        }

        @include mq('to', 577) {
            margin-top: 16px;
        }

        a {
            text-decoration: underline;
            color: $grey;

            @media (hover: hover) {
                &:hover {
                    color: $black;
                }
            }
        }
    }

    //*Button for error pages
    &__error-page-btn {
        margin-top: 24px;
    }

    &__popup {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 16;
        background-color: $white;
    }
}
