@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-label-without-bg {
    @include flexMiddle;
    @include creatoBold;

    span {
        min-width: 9px;
        max-width: 9px;
        min-height: 9px;
        max-height: 9px;
        display: block;
        margin-right: 12px;
        border-radius: 50%;
    }

    &--Open,
    &--Wizardnotfinished,
    &--ConfirmationCheck,
    &--SupplierOrderSent,
    &--Planned,
    &--Draft,
    &--grey,
    &--DisqualificationOther,
    &--DisqualifiedPrivate {
        span {
            background: $grey;
        }
    }


    &--Open.frameworkAgreementStatus,
    &--SecondReminderSent,
    &--Remindersent,
    &--Update,
    &--Partnerofferreceived,
    &--LiveinPortal,
    &--ReadyForLP,
    &--ProfiCheck,
    &--New,
    &--CalculationRunning,
    &--WaitingForConfirmation,
    &--SupplierFASent,
    &--InExamination,
    &--yellow {
        span {
            background: $yellow;
        }
    }

    // for not set up color/variable
    &--black {
        span {
            background: $black;
        }
    }

    &--Offeraccepted,
    &--InProgress,
    &--InvoicePaid,
    &--InvoiceOpen,
    &--Paid,
    &--Sent,
    &--Shipped,
    &--Completed {
        span {
            background: $green;
        }
    }

    &--InProgress.frameworkAgreementStatus,
    &--FAConfirmed,
    &--Offersent,
    &--Partialshipment,
    &--ReadyToPack,
    &--QASurface,
    &--Surface,
    &--QA,
    &--PartialQA,
    &--InProduction,
    &--OfferCreated,
    &--purple {
        span {
            background: $purple;
        }
    }

    &--Complaint,
    &--Overdue,
    &--PaymentDelay,
    &--Clarification,
    &--Inkasso,
    &--Cancelled,
    &--ProcessingDelay,
    &--Deleted,
    &--Lost,
    &--red,
    &--Aborted,
    &--Waitingforanswer {
        span {
            background: $red;
        }
    }

    &--UpdateSent {
        span {
            background: $orange;
        }
    }

    &--InNegotiation {
        span {
            background: $lightBlue;
        }
    }

    // contacts status
    &.contactsStatus {
        &.c-label-without-bg--Active {
            span {
                background: $green;
            }
        }

        &.c-label-without-bg--Invited {
            span {
                background: $yellow;
            }
        }

        &.c-label-without-bg--Notinvited {
            span {
                background: $grey;
            }
        }
    }

    // specific offers statuses
    &.offersStatus {
        &.c-label-without-bg--Open {
            span {
                background: $yellow;
            }
        }

        &.c-label-without-bg--Lost {
            span {
                background: $grey;
            }
        }
    }
}