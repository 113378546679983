@import '../../scss/variables';
@import '../../scss/mixins';

.c-date-picker {
    @include mq('from', 1640) {
        position: relative;
    }

    @include mq('to', 1330) {
        position: relative;
    }

    @include mq('to', 768) {
        width: 100%;
    }

    &:not(.c-date-picker--single-date) {
        @include mq('fromto', 575, 1339) {
            padding-bottom: 24px;
        }

        @include mq('to', 575) {
            padding-bottom: 16px;
        }
    }

    &--single-date {
        @include mq('fromto', 1339, 1640) {
            position: relative;
        }
    }

    &__action {
        border: 2px solid $paleGrey;
        cursor: pointer;
        height: 44px;
        @include flexCenterMiddle;
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;
        border-radius: 4px;
        background: transparent;
        transition: $hoverTransition;
        margin-left: auto;
        position: relative;

        @include mq('from', 576) {
            width: 210px;
        }

        @include mq('to', 576) {
            width: 100%;
            justify-content: space-between;
            padding: 0 16px;
        }

        &:hover {
            border-color: $grey;
        }

        &--focused {
            border-color: $black;
        }

        &--single-date {
            margin-left: unset;
            justify-content: unset;
            padding: 13px 38px 13px 12px;
            color: $grey;
            font-family: $secondaryFont;
            font-weight: 400;
            width: 100%;

            @include mq('from', 768) {
                width: 272px;
                max-width: 40%;
            }
        }

        &--error {
            border-color: $red;

            :global {
                .icon-calendar {
                    color: $red;
                }
            }
        }

        :global {
            .icon-arrow-right {
                margin: 0 11px;
            }

            .icon-calendar {
                position: absolute;
                right: 13.5px;
            }
        }
    }

    &__error {
        color: $red;
        font-size: 14px;
        line-height: 18px;
        padding-top: 8px;
    }
}
