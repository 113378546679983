@import '../../scss/variables';
@import '../../scss/mixins';

.c-login-redirection {
    text-align: center;
    @include creatoBold;
    color: $grey;
    line-height: 20px;
    margin-top: 30px;

    a {
        color: $purple;

        @media (hover: hover) {
            &:hover {
                color: $purple-hover;
            }
        }
    }
}