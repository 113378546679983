@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.c-contact-info {
    &__label {
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        margin-bottom: 14px;
        @include flexMiddle;

        &--small-margin {
            margin-bottom: 8px;
        }

        button {
            outline: none;
            background: none;
            border: none;
            cursor: pointer;
            height: 13px;
            transition: $hoverTransition;

            &:focus {
                outline: none;
            }
        }

        &--invoicing-confirm {
            margin-bottom: 16px;
        }
    }

    &__contact {
        font-size: 14px;
        line-height: 18px;

        // when contact info has background
        &--with-background {
            padding: 6px 12px;
            background: $lightGrey;
            border-radius: 4px;
        }
    }

    &__contact-name {
        color: $black;
    }

    &__contact-email {
        font-size: 12px;
        line-height: 14px;
        color: $grey;
        margin-top: 4px;
    }

    &__additional-info {
        position: relative;
        margin-left: 4px;
        @include flexMiddle;

        button {
            @media (hover:hover) {
                &:hover {
                    & + * {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }
}
