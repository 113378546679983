@font-face {
    font-family: 'icomoon';
    src:  url('../../fonts/icomoon/icomoon.eot?ffjt8c');
    src:  url('../../fonts/icomoon/icomoon.eot?ffjt8c#iefix') format('embedded-opentype'),
      url('../../fonts/icomoon/icomoon.ttf?ffjt8c') format('truetype'),
      url('../../fonts/icomoon/icomoon.woff?ffjt8c') format('woff'),
      url('../../fonts/icomoon/icomoon.svg?ffjt8c#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }


[class^="icon-"]:before,
[class*=" icon-"]:before,
%icomoon:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // display: block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-zip:before {
    content: '\e920';
}
.icon-csv:before {
    content: '\e937';
}
.icon-xls:before {
    content: '\e938';
}
.icon-pdf:before {
    content: '\e939';
}
.icon-info:before {
    content: '\e936';
}
.icon-circleCheckmark:before {
    content: '\e917';
}
.icon-checkmark-with-green-bg .path1:before {
    content: '\e934';
    color: rgb(135, 204, 155);
}
.icon-checkmark-with-green-bg .path2:before {
    content: '\e935';
    margin-left: -1em;
    color: rgb(255, 255, 255);
}
.icon-checkmark:before {
    content: '\e908';
}
.icon-exclamation-mark:before {
    content: '\e933';
}
.icon-contract:before {
    content: '\e932';
}
.icon-search-plus:before {
    content: '\e931';
}
.icon-expand:before {
    content: '\e930';
}
.icon-settings:before {
    content: '\e92e';
}
.icon-logout:before {
    content: '\e92f';
}
.icon-arrow-right-45deg:before {
    content: '\e900';
}
.icon-arrow-right:before {
    content: '\e901';
}
.icon-attachment:before {
    content: '\e902';
}
.icon-bell:before {
    content: '\e903';
}
.icon-bookmark:before {
    content: '\e904';
}
.icon-calendar:before {
    content: '\e905';
}
.icon-chat:before {
    content: '\e906';
}
.icon-checked-note:before {
    content: '\e907';
}
.icon-checkmark-circle:before {
    content: '\e909';
}
.icon-chevron-down:before {
    content: '\e90a';
}
.icon-chevron-down-thin:before {
    content: '\e90b';
}
.icon-chevron-left:before {
    content: '\e90c';
}
.icon-chevron-left-border:before {
    content: '\e90d';
}
.icon-chevron-left-thin:before {
    content: '\e90e';
}
.icon-chevron-right:before {
    content: '\e90f';
}
.icon-chevron-right-border:before {
    content: '\e910';
}
.icon-chevron-right-thin:before {
    content: '\e911';
}
.icon-chevron-up:before {
    content: '\e912';
}
.icon-chevron-up-thin:before {
    content: '\e913';
}
.icon-connected:before {
    content: '\e914';
}
.icon-download:before {
    content: '\e915';
}
.icon-edit:before {
    content: '\e916';
}
.icon-file-2:before {
    content: '\e918';
}
.icon-folder:before {
    content: '\e919';
}
.icon-grid:before {
    content: '\e91a';
}
.icon-hamburger-icon-1:before {
    content: '\e91b';
}
.icon-hamburger-icon-full:before {
    content: '\e91c';
}
.icon-horizontal-three-dots:before {
    content: '\e91d';
}
.icon-minus:before {
    content: '\e91e';
}
.icon-notification-icon:before {
    content: '\e91f';
}
.icon-plus:before {
    content: '\e921';
}
.icon-print:before {
    content: '\e922';
}
.icon-question-mark:before {
    content: '\e923';
}
.icon-rectangle-layer:before {
    content: '\e924';
}
.icon-reload:before {
    content: '\e925';
}
.icon-search:before {
    content: '\e926';
}
.icon-square-layer:before {
    content: '\e927';
}
.icon-three-dots-in-circle:before {
    content: '\e928';
}
.icon-trash:before {
    content: '\e929';
}
.icon-upload:before {
    content: '\e92a';
}
.icon-vertical-three-dots:before {
    content: '\e92b';
}
.icon-warning:before {
    content: '\e92c';
}
.icon-x-sign:before {
    content: '\e92d';
}
