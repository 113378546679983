@import '../../scss/mixins';
@import '../../scss/variables';

.c-main-layout {
    width: 100%;
    flex: 1;

    &--has-sidebar {
        @include mq('from', 1281) {
            padding-left: 264px;
        }

        @include mq('fromto', 769, 1281) {
            padding-left: 64px;
        }
    }
}