@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-date-picker-dropdown {
    position: absolute;
    top: 54px;
    background-color: $white;
    z-index: 3;
    box-shadow: 0px 8px 24px rgba(17, 21, 34, 0.08);

    @include mq('from', 768) {
        padding: 32px 32px 20px;
    }

    @include mq('to', 768) {
        overflow: auto;
    }

    @include mq('fromto', 485, 768) {
        max-width: 455px;
        padding: 20px 20px 10px;
    }

    @include mq('to', 485) {
        padding: 10px 10px 10px;
    }

    @include mq('fromto', 390, 485) {
        max-width: 348px;
    }

    @include mq('from', 390) {
        right: 0;
    }

    @include mq('to', 390) {
        left: -10px;
        right: -10px;
    }

    &--offers {
        @include mq('fromto', 1330, 1640) {
            right: 0;
        }
    }

    &--singleDate {
        @include mq('from', 768) {
            padding: 32px;
        }

        @include mq('fromto', 485, 768) {
            padding: 20px 20px 32px;
        }

        @include mq('to', 485) {
            padding: 10px 10px 32px;
        }
    }

    &__header {
        display: flex;

        &--single-date {
            h2 {
                margin: 16px 0 5px;

                @include mq('from', 768) {
                    font-size: 36px;
                    line-height: 44px;
                }

                @include mq('to', 768) {
                    font-size: 26px;
                    line-height: 30px;
                }
            }

            button {
                position: absolute;
                top: 24px;
                outline: none;
                background: none;
                border: none;
                cursor: pointer;
                width: 32px;
                height: 32px;
                transform-origin: center center;
                transition: all 0.3s ease;
                @include flexCenterMiddle;

                @include mq('from', 768) {
                    right: 24px;
                }

                @include mq('fromto', 485, 768) {
                    right: 20px;
                }

                @include mq('to', 485) {
                    right: 10px;
                }

                @media (hover: hover) {
                    &:hover {
                        transform: rotate(180deg);
                    }
                }

                i {
                    font-size: 18px;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
}
