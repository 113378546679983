@import '../../../scss/mixins';
@import '../../../scss/variables';

.wizzard-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $grey-o24;
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    background-color: $white;
    width: 100%;
    left: 0;
    z-index: 1;

    @include mq('from', 1281) {
        padding: 24px 64px 22px;
    }

    @include mq('fromto', 769, 1281) {
        padding: 16px 32px;
    }

    @include mq('fromto', 376, 769) {
        padding: 16px 24px;
    }

    @include mq('to', 376) {
        padding: 16px;
    }

    &__next-btn {
        margin-left: auto;
    }

    // &--orderDetails {
    //     padding-bottom: 0;

    //     @include mq('from', 1281) {
    //         width: calc(100% + 128px);
    //         margin: 20px -64px 0;
    //     }

    //     @include mq('fromto', 769, 1281) {
    //         width: calc(100% + 64px);
    //         margin: 20px -32px 0;
    //     }

    //     @include mq('fromto', 376, 769) {
    //         width: calc(100% + 48px);
    //         margin: 20px -24px 0;
    //     }

    //     @include mq('to', 376) {
    //         width: calc(100% + 32px);
    //         margin: 20px -16px 0;
    //     }
    // }
}
