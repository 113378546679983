@import '../../scss/variables';
@import '../../scss/mixins';

.c-dropzone {
    min-height: 44px;
    max-height: 44px;
    border-radius: 4px;
    border: 2px solid $paleGrey;
    padding: 0 16px;
    display: flex;
    align-items: center;
    transition: $hoverTransition;
    position: relative;

    &:hover {
        border-color: $grey;
    }

    p {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        @include creatoBold;
    }

    i {
        color: $grey;
        position: absolute;
        right: 16px;
        font-size: 13.4px;
    }
}