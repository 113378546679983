@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-details-sidescreen {
    background: $lightGrey;

    @include mq('from', 1281) {
        min-width: 528px;
        max-width: 528px;
    }

    @include mq('from', 769) {
        padding: 32px;
    }

    @include mq('fromto', 375, 769) {
        padding: 96px 24px 24px;
    }

    @include mq('to', 375) {
        padding: 80px 16px 10px;
    }

    &__back-btn-skeleton {
        width: 53px;
        height: 18px;
        @include skeletonLoaderDefaults;
    }

    &__status-data-skeleton {
        width: 110px;
        height: 18px;
        @include skeletonLoaderDefaults;

        &--mt-24 {
            margin-top: 24px;
        }
    }

    &__bg-label-skeleton {
        width: 110px;
        height: 32px;
        @include skeletonLoaderDefaults;
    }

    &__fa-and-docs-skeleton {
        width: 110px;
        height: 24px;
        margin-bottom: 24px;
        @include skeletonLoaderDefaults;
    }

    &__price-skeleton {
        width: 110px;
        height: 29px;
        @include skeletonLoaderDefaults;

        @include mq('to', 769) {
            height: 18px;
        }
    }

    &__main-info {
        @include mq('fromto', 577, 1281) {
            display: flex;
            justify-content: space-between;
        }
    }

    h1 {
        margin: 16px 0 3px;
    }

    &__heading {
        margin: 16px 0 3px;

        @include mq('to', 768) {
            font-size: 24px;
            line-height: 28.5px;
        }

        &--skeleton {
            width: 175px;
            height: 44px;
            @include skeletonLoaderDefaults;

            @include mq('to', 768) {
                height: 28.5px;
            }
        }
    }

    &__id {
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        @include creatoBold;

        &--skeleton {
            width: 65px;
            height: 18px;
            @include skeletonLoaderDefaults;
        }
    }

    &__details {
        position: relative;

        @include mq('from', 769) {
            margin-top: 40px;
        }

        @include mq('from', 375) {
            display: flex;
            flex-wrap: wrap;
        }

        @include mq('fromto', 375, 769) {
            margin-top: 24px;
        }

        @include mq('to', 375) {
            margin-top: 16px;
        }

        @include mq('to', 1281) {
            &.expandedStatus {
                &::after {
                    border-width: 0 32px 8px 32px;
                    border-color: transparent transparent $grey-o24 transparent;

                    @include mq('from', 769) {
                        bottom: -30px;
                    }

                    @include mq('fromto', 375, 769) {
                        bottom: -23px;
                    }

                    @include mq('to', 375) {
                        bottom: 2px;
                    }
                }
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-style: solid;
                border-width: 8px 32px 0 32px;
                border-color: $grey-o24 transparent transparent transparent;
                transition: all 0.3s ease;
                z-index: 2;

                @include mq('from', 769) {
                    bottom: -40.4px;
                }

                @include mq('fromto', 375, 769) {
                    bottom: -32.3px;
                }

                @include mq('to', 375) {
                    bottom: -8.3px;
                }
            }
        }
    }

    &__parts-comments {
        width: 100%;

        @include mq('to', 1281) {
            display: flex;
        }

        @include mq('to', 768) {
            display: block;
        }
    }

    &__num-of-uploaded-files {
        display: block;
        margin-bottom: 8px;
    }

    &__files-uploaded {
        display: flex;
        width: 100%;
        gap: 16px;
        flex-wrap: wrap;
    }

    &__detail {
        font-size: 14px;
        line-height: 18px;
        word-break: break-word;

        @include mq('from', 1281) {
            @include flexGrid(32px, 2);
        }

        @include mq('fromto', 577, 1281) {
            @include flexGrid(16px, 3);
        }

        @include mq('fromto', 375, 577) {
            @include flexGrid(16px, 2);
        }

        @include mq('to', 375) {
            margin-bottom: 16px;
        }

        &:nth-child(7) {
            display: none;
        }

        &:nth-child(8) {
            @include mq('fromto', 577, 1281) {
                transform: translateX(16px);
            }
        }

        &--relative {
            position: relative;
        }

        &--uploaded-files {
            flex: none;
            max-width: 100%;

            @include mq('from', 1281) {
                margin-bottom: 0;
            }
        }

        &--comments {
            max-width: 100%;
        }

        &--hidden {
            @include mq('to', 1281) {
                display: none;
            }
        }

        &--full-width {
            @include mq('from', 1281) {
                flex: 1;
                max-width: 100%;
            }
        }

        &--for-offers-mob {
            @include mq('from', 768) {
                display: none;
            }
        }
    }

    &__detail-offers-price {
        @include mq('from', 1281) {
            min-width: 300px;
        }

        @include mq('fromto', 577, 1281) {
            transform: translateX(16px);
        }

        &--diff-status {
            @include mq('from', 1281) {
                margin-top: -32px;
            }

            @include mq('fromto', 577, 1281) {
                transform: translateX(0);
            }
        }

        &--certs-origin {
            @include mq('from', 1281) {
                margin-top: 0;
            }
        }
    }

    &__detail-title {
        color: $grey;
        margin-bottom: 8px;

        &--skeleton {
            width: 110px;
            height: 18px;
            @include skeletonLoaderDefaults;
        }

        &--small,
        &--small-italic,
        &--medium {
            margin-bottom: 4px;
        }

        &--small,
        &--small-italic {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 4px;
        }

        &--small-italic {
            font-style: italic;
        }

        &--medium {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__detail-data {
        color: $black;

        &--purple-price {
            color: $purple !important;
        }
    }

    &__boxed-info {
        border-top: 1px solid $grey-o24;
        width: 100%;
        margin-top: 8px;

        @include mq('from', 1281) {
            padding: 32px 0 20px;
        }

        @include mq('fromto', 375, 1281) {
            padding: 24px 0 20px;
        }

        @include mq('to', 375) {
            padding: 17px 0 24px;
        }

        &--limited {
            @include mq('from', 1281) {
                margin-top: 40px;
            }

            @include mq('fromto', 576, 1281) {
                border-top: none;
                margin-top: 8px;
            }

            > div {
                @include mq('fromto', 576, 1281) {
                    margin-top: -24px;
                    padding-top: 24px;
                    border-top: 1px solid $grey-o24;
                    max-width: calc(((100% - 32px) / 3 * 2) + 16px);
                }
            }
        }

        h4 {
            margin-bottom: 24px;
        }
    }

    h3 {
        @include mq('to', 769) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &__document-download {
        color: $grey;
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;

        i {
            font-size: 18px;
            margin-right: 8px;
        }

        &--no-cursor {
            color: $black;
            cursor: unset;
        }
    }

    &__expand-container {
        position: relative;
        background: $lightGrey;

        @include mq('to', 1281) {
            max-height: 0;
            transition: all 0.4s ease;
            overflow: hidden;
            border-bottom: 2px solid $grey-o24;
        }

        @include mq('from', 1281) {
            margin: 0 -32px -32px;
        }

        @include mq('from', 769) {
            width: calc(100% + 64px);
            padding: 0 32px 32px;
        }

        @include mq('fromto', 769, 1281) {
            margin: 10px -32px -32px;
        }

        @include mq('fromto', 375, 769) {
            width: calc(100% + 48px);
            padding: 0 24px 30px;
            margin: 10px -24px -24px;
        }

        @include mq('to', 375) {
            width: calc(100% + 32px);
            padding: 0 16px 30px;
            margin: 0 -16px -16px;
        }

        &.expanded {
            .c-details-sidescreen__expand-content {
                opacity: 1;
            }
        }
    }

    &__expand-action-box {
        position: absolute;
        bottom: 0;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        width: 100%;
        left: 0;
        z-index: 2;
        background-color: $lightGrey;
    }

    &__expand-action {
        cursor: pointer;
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;
        color: $purple;
        z-index: 2;
        transition: $hoverTransition;

        &:hover {
            color: $purple-hover;
        }
    }

    &__expand-content {
        transition: 1s ease;

        @include mq('to', 1281) {
            opacity: 0;
        }

        @include mq('from', 375) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__link {
        color: $purple;
        transition: $hoverTransition;
        @include creatoBold;
        @include inlineFlexMiddle;

        &:hover {
            color: $purple-hover;
        }

        i {
            margin-right: 10px;
        }
    }

    &__download-pdf-container-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
    }

    &__download-pdf-container {
        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &--uploaded {
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                width: 100%;
                overflow: hidden;
            }
        }
    }

    &__uploaded-file-wrapper {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-width: 140px;
        min-width: 140px;
        position: relative;

        i {
            transition: $hoverTransition;
        }

        span {
            transition: $hoverTransition;
        }

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
                i {
                    color: $black;
                }

                span {
                    color: $black;
                }

                .c-details-sidescreen__uploaded-file-full-name {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
    }

    &__uploaded-file-full-name-wrapper {
        position: absolute;
        left: 26px;
        top: 25px;
        pointer-events: none;
    }

    &__uploaded-file-full-name {
        display: flex;
        background-color: $white;
        pointer-events: none;
        min-width: 240px;
        position: relative;
        padding: 8px;
        opacity: 0;
        transition: $hoverTransition;
        border-radius: 2px;
        box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);

        &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            margin-left: 28px;
            top: 0em;
            left: 20px;
            box-sizing: border-box;
            border: 5px solid;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: 0 0 24px 0 rgba(17, 21, 34, 0.08);
        }
    }

    &__download-pdf {
        cursor: pointer;
        transition: $hoverTransition;
        display: inline-flex;

        &:hover {
            color: $black;
        }
    }

    &__one-line {
        display: flex;
        flex-wrap: wrap;
        white-space: break-spaces;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
