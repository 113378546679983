@import '../../scss/mixins';
@import '../../scss/variables';

.c-wizzard-order-details {
    @include mq('from', 1281) {
        display: flex;
    }

    > div {
        max-width: 728px;
        flex: 1;

        @include mq('from', 1281) {
            margin-bottom: 30px;
        }
    }

    &__left-side {
        @include mq('from', 1800) {
            margin-right: 184px;
        }

        @include mq('fromto', 1281, 1800) {
            margin-right: 92px;
        }
    }

    &__right-side {
        @include mq('from', 1920) {
            margin-right: 152px;
        }

        @include mq('from', 1281) {
            margin-top: 19.5px;
        }

        @include mq('to', 1281) {
            margin-top: 64px;
        }
    }

    &__right-side-fa-details {
        @include mq('from', 1281) {
            margin-top: 0;
        }
    }

    &__offer-num {
        @include creatoBold;
        font-size: 14px;
        line-height: 18px;
        color: $grey;
    }

    // Billing Adress
    &__billing-address {
        margin-top: 64px;
    }

    // Order Information
    &__order-information-container {
        h2 {
            margin-top: 66px;
            font-size: 24px;
            line-height: 28.5px;

            @include mq('to', 768) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        &--reduced-margin {
            h2 {
                @include mq('from', 1281) {
                    margin-top: 7px;
                }

                @include mq('to', 1281) {
                    margin-top: 0;
                }
            }
        }
    }

    &__order-information {
        @include mq('from', 768) {
            display: flex;
            margin-bottom: 26px;
        }

        > div {
            margin-bottom: 0;

            &:first-child {
                @include mq('from', 768) {
                    margin-right: 32px;
                    width: 272px;
                    max-width: 40%;
                }
            }
        }
    }

    &__input-layout {
        @include mq('from', 768) {
            width: 100%;
            display: flex;
        }

        > div {
            &:nth-child(2) {
                @include mq('from', 768) {
                    margin-left: 32px;
                }
            }
        }

        &--added-margin {
            > div {
                &:nth-child(1) {
                    @include mq('from', 768) {
                        margin-top: 2px;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &__plz-input {
        width: 30%;
    }

    &__dropzone {
        cursor: pointer;
        flex: 1;

        @include mq('from', 768) {
            margin-top: 52px;
        }

        @include mq('to', 768) {
            margin-top: 24px;
        }
    }

    &__comment {
        width: 100%;
        height: 113px !important;
    }

    &__item-label {
        font-size: 14px;
        line-height: 18px;
        color: $grey;
        margin: 24px 0 8px;
    }

    &__input {
        &--small {
            @include mq('from', 768) {
                max-width: 120px;
                min-width: 120px;
            }
        }

        &--large {
            flex: 1;
        }
    }

    &__error-message {
        background-color: $light-yellow;
        padding: 24px;
        border-radius: 4px;
        margin-top: 16px;
        color: $black;
        line-height: 20px;
    }

    &__contacts {
        h2 {
            margin-top: 43px;
        }
    }

    &__contacts-dropdowns {
        @include mq('from', 768) {
            @include flexMultipleItemsInRow(24px, 0,  2);
        }
    }
}
