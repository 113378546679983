@import '../../../scss/mixins';
@import '../../../scss/variables';
.offer-success {
    width: 100%;

    &__content-wrapper {
        @include mq('from', 1281) {
            padding: 0 64px 64px;
        }

        @include mq('fromto', 769, 1281) {
            padding: 0 32px 32px;
        }

        @include mq('fromto', 376, 769) {
            padding: 0 24px 24px;
        }

        @include mq('to', 376) {
            padding: 0 16px 16px;
        }
    }

    &__content {
        max-width: 728px;

        @include mq('from', 768) {
            margin: 120px auto 0;
        }

        @include mq('to', 768) {
            margin: 60px auto 0;
        }
    }

    &__description {
        margin-top: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $grey;
    }

    &__order-details {
        margin-top: 32px;
        padding: 32px 24px;
        background-color: $lightGrey;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        a {
            display: flex;
            align-items: center;
            color: $grey;
            font-size: 14px;
            line-height: 18px;
            @include creatoBold;

            @media (hover: hover) {
                &:hover {
                    color: $black;
                }
            }

            i {
                margin-right: 8px;
                font-size: 12px;
            }
        }
    }

    &__order-details-link-wrap {
        margin-left: 32px;
    }

    &__order-details-name {
        @include mq('from', 768) {
            font-size: 36px;
            line-height: 44px;
        }

        @include mq('to', 768) {
            font-size: 24px;
            line-height: 28.5px;
        }
    }

    &__order-details-id {
        @include creatoBold;
        color: $grey;
        font-size: 14px;
        line-height: 18px;
        margin-top: 3px;
    }
}
