@import '../../../scss/variables';
@import '../../../scss/mixins';

.download-pdf-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px 13px 16px;
    background-color: $lightGrey;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
    transition: $hoverTransition;
    border: 2px solid transparent;
    max-height: 44px;

    @media (hover: hover) {
        &:hover {
            border-color: $grey;
        }
    }

    span {
        color: $black;
        font-size: 14px;
        line-height: 18px;
        max-width: 90%;
        @include creatoBold;
        @include overflowEllipsis;
    }

    i {
        color: $black;
        font-size: 14px;
    }
}
