@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-order-contacts {
    max-width: 646px;
    border-bottom: 1px solid rgba($grey, 0.24);
    padding-bottom: 38px;

    &__contact {
        margin-top: 24px;
    }

    &__one-row {
        @include mq('from', 768) {
            @include flexMultipleItemsInRow(24px, 0px, 2);
        }
    }
}