@import '../../scss/variables';

.c-icons {
    &__icon {
        :global {
            .icon-path {
                transition: $hoverTransition;
            }
        }
    }
}