@import '../../scss/mixins';
@import '../../scss/variables';

.error-page {
    width: 100%;
    display: flex;

    &__description {
        margin-bottom: 40px;

        a {
            color: $purple;
            text-decoration: underline;
            transition: $hoverTransition;

            @media (hover: hover) {
                &:hover {
                    color: $purple-hover;
                }
            }
        }
    }
}
