@import '../../../../../scss/variables';
@import '../../../../../scss/mixins';

.c-price-label-and-price {
    padding: 0 16px 16px;

    &__formated-price {
        @include creatoBold;

        &--large {
            font-size: 24px;
            line-height: 28.5px;
        }
    }
}

