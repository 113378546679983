@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.c-confirm-register {
    height: 100%;
    @include flexColumnBetween;

    h1 {
        @include mq('to', 577) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    &__description {
        margin: 15px 0 39px;
        color: $grey;
    }

    &__help-email {
        @include creatoBold;
        color: $grey;
    }

    &__submit-button {
        margin-top: 40px;
    }

    &__link-button {
        background: none;
        padding: 0;
        color: $grey;
        @include arialNormal;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;

        @include mq('from', 577) {
            margin-top: 32px;
        }

        @include mq('to', 577) {
            margin-top: 16px;
        }

        @media(hover: hover) {
            &:hover {
                color: $black;
            }
        }
    }
}
