@import '../../../../../../scss/mixins';
@import '../../../../../../scss/variables';

.restore-items {
    background-color: $light-yellow;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 16px;

    @include mq('to', 768) {
        flex-direction: column;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $secondaryFont;
        color: $black;
        padding-right: 30px;

        @include mq('to', 768) {
            padding-right: 0;
            margin-bottom: 32px;
        }
    }

    &__btn {
        min-width: 199px;
    }
}
