@import '../../../../scss/variables';
@import '../../../../scss/mixins';

.c-table-list-item-header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
        @include mq('from', 1280) {
            padding: 5px 8px 5px 24px;
        }

        @include mq('to', 1280) {
            padding: 5px 8px 5px 16px;
        }
    }

    &:not(:first-child):not(:last-child) {
        padding: 5px 8px;
    }

    &.frameworkComponents,
    &.orderComponents {
        &:not(:first-child) {
            > div {
                &:first-child {
                    font-size: 14px;
                    line-height: 18px;
                    color: $grey;
                    margin-bottom: 4px;
                }

                &:last-child {
                    @include creatoBold;
                    line-height: 20px;
                }
            }
        }

        &:first-child {
            flex: 1;

            @include mq('from', 1280) {
                min-width: 210px;
                width: calc((100% - 76px) * 0.21);
            }

            @include mq('to', 1280) {
                min-width: 200px;
                width: calc((100% - 68px) * 0.21);
            }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            min-width: 126px;

            @include mq('from', 1280) {
                width: calc((100% - 76px) * 0.127);
            }

            @include mq('to', 1280) {
                width: calc((100% - 68px) * 0.127);
            }
        }

        &:nth-child(5) {
            min-width: 120px;
        }

        &:nth-child(6) {
            border-left: 1px solid $lightGrey2;
            margin: 25px 0;
            padding: 0 8px 0 24px;
            min-width: 130px;
        }

        &:nth-child(7) {
            min-width: 120px;
            text-align: right;

            .c-table-list-item-header__data-container {
                justify-content: flex-end;

                > span {
                    padding-right: 0;
                }
            }
        }

        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(5) {
            @include mq('from', 1280) {
                width: calc((100% - 76px) * 0.12);
            }

            @include mq('to', 1280) {
                width: calc((100% - 68px) * 0.13);
            }
        }
    }

    &__data-container {
        @include flexMiddle;
    }

    &__data {
        min-width: 42px;
        padding-right: 7px;

        &--download {
            color: $purple;
            transition: $hoverTransition;
            cursor: pointer;
            @include creatoBold;

            &:hover {
                color: $purple-hover;
            }

            i {
                margin-right: 8px;
                font-size: 15px;
                position: relative;
                top: 1px;
            }
        }
    }

    &__in-progress {
        height: 4px;
        width: 100%;
        background: linear-gradient(to right, rgba(131, 138, 156, 0.32) 0%, #838A9C 100%);
        border-radius: 2px;
        flex: 1;
    }

    &.orderDeliveryPopup {
        font-size: 14px;
        line-height: 18px;

        &:first-child {
            min-width: 183px;
            width: calc((225 / 896) * 100%);
            flex: 1;

            .c-table-list-item-header__data-container {
                @include creatoBold;
                font-size: 16px;
                line-height: 20px;
            }
        }

        &:nth-child(2) {
            min-width: 153px;
            width: calc((176 / 896) * 100%);
        }

        &:nth-child(3) {
            min-width: 183px;
            width: calc((205 / 896) * 100%);
        }

        &:nth-child(4) {
            min-width: 173px;
            width: calc((193 / 896) * 100%);

            .c-table-list-item-header__data {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;
            }
        }

        .c-table-list-item-header__data-title {
            color: $grey;
            margin-bottom: 8px;
        }

        .c-table-list-item-header__data-container {
            color: $black;
        }
    }
}
// 760px - 68px = 692