@import '../../../scss/mixins';
@import '../../../scss/variables';

.c-framework-agreeement-components {
    &__actions {
        @include mq('from', 1600) {
            @include flexBetween;
        }

        @include mq('fromto', 800, 1281) {
            @include flexBetween;
        }
    }

    &__filters {
        @include mq('fromto', 1281, 1600) {
            margin-bottom: 24px;
        }

        @include mq('to', 576) {
            margin-bottom: 24px;
        }
    }
}