.c-table-list-item-content {
    overflow: hidden;
    max-height: 0;

    &--expanded {
        max-height: 100%;
    }


    &,
    &--expanded {
        transition: all 0.3s;
        overflow: hidden;
    }
}