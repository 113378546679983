@import '../../scss/variables';

.c-error {
    background-color: $light-red;
    padding: 24px;
    border-radius: 4px;
    margin: 24px 0 40px;

    &__message {
        color: $black !important;
        font-weight: 400;
        font-size: 14px;
    }
}