@import '../../../scss/variables';
@import '../../../scss/mixins';

.c-email-confirm {
    background: $black;
    width: 100%;
    height: 100vh;

    &__header {
        height: 120px;
        max-width: 1920px;
        margin: 0 auto;
        @include flexBetweenMiddle;

        @include mq('from', 768) {
            padding: 10px 53px 10px 64px;
        }

        @include mq('fromto', 376, 769) {
            padding: 10px 24px 10px 24px;
        }

        @include mq('to', 376) {
            padding: 10px 16px 10px 16px;
        }

        a {
            img {
                @include mq('from', 577) {
                    width: 118px;
                    height: 40px;
                }

                @include mq('to', 577) {
                    width: 101px;
                    height: 24px;
                }
            }
        }
    }

    &__header-actions {
        @include flexMiddle;

        a {
            margin-right: 34px;
        }
    }

    h1 {
        color: $white;

        @include mq('from', 577) {
            font-size: 48px;
            line-height: 52px;
        }

        @include mq('to', 577) {
            font-size: 24px;
            line-height: 29px;
        }
    }

    p {
        color: $grey;

        @include mq('from', 577) {
            margin: 16px 0 57px;
        }

        @include mq('to', 577) {
            font-size: 14px;
            line-height: 18px;
            margin: 16px 0 37px;
        }
    }

    &__container {
        background-color: $black;

        @include mq('from', 1920) {
            padding: 136px 53px 136px 518px;
        }

        @include mq('fromto', 1280, 1920) {
            padding: 136px 53px 136px 368px;
        }

        @include mq('fromto', 769, 1280) {
            padding: 136px 53px 136px 64px;
        }

        @include mq('fromto', 577, 769) {
            padding: 136px 24px 136px 24px;
        }

        @include mq('fromto', 376, 577) {
            padding: 46px 24px 136px 24px;
        }

        @include mq('to', 376) {
            padding: 46px 16px 136px 16px;
        }

        img {
            @include mq('from', 577) {
                margin-bottom: 48px;
                margin-left: 8px;
            }

            @include mq('to', 577) {
                margin-left: 0;
                margin-bottom: 28px;
            }
        }

        a {
            margin-top: 50px;
        }
    }
}
